import { createAction } from 'redux-actions';
import { Logger } from 'aws-amplify';
import { createBrowserHistory } from 'history';
import fetch from 'utils/fetch';
import {
  GET_ORGANIZATION,
  RESET_SELECTED_ORGANIZATION,
  GET_USER_LIST_OF_ORGANIZATIONS,
  SET_SELECTED_ORGANIZATION_ID,
  UPDATE_USER_STATUS_ON_SPECIFIC_EVENT,
  SET_SELECTED_EVENT_ID,
  REMOVE_USER_TO_AN_EVENT,
  ADD_USER_TO_AN_ORGANIZATION,
  ADD_USER_TO_AN_EVENT,
  REMOVE_USER_TO_AN_ORGANIZATION,
  GET_ORGANIZATION_STAFF_LIST,
  REMOVE_SELECTED_ORGANIZATION_ID,
  REMOVE_SELECTED_ORGANIZATION_EVENT_ID,
  SET_ORGANIZATION_STAFF,
  ORGANIZATION_STAFF_IS_FETCHED,
  UPDATE_STAFF_DETAILS,
  UPDATE_STAFF_EVENT_ROLE,
  GET_STAFF_EVENTS_LIST,
  FETCHING_ORGANIZATION_STAFF,
  CREATE_ORGANIZATION,
  DELETE_ORGANIZATION,
  SEARCH_ORGANIZATION_STAFFS,
  // events
  SET_IS_FETCHED_ORGANIZATION_EVENTS,
  FETCH_ORGANIZATION_EVENTS,
  FETCH_ORGANIZATION_DASHBOARD_DATA,
  IS_FETCHED_ORGANIZATION_DASHBOARD_DATA,
  UPDATE_ORGANIZATION_DATA,
  SET_UPDATING_ORGANIZATION,
  CREATE_NEW_EVENT,
  ADD_EVENT_TO_EVENT_LISTS,

  // others
  UPDATE_IMAGE_LINK,
} from 'modules/organization/types';
import { push } from 'connected-react-router';
import { userSearchType } from 'modules/user/constants';
import {
  createUserOrganizationWithRoles as createUserOrganizationWithRolesMutation,
  // deleteUserOrganizationWithRoles as deleteUserOrganizationWithRolesMutation,
  updateUserOrganizationWithRoles as updateUserOrganizationWithRolesMutation,
  updateUserOrganizationEventJunction as updateUserOrganizationEventJunctionMutation,
  // deleteUserOrganizationEventJunction as deleteUserOrganizationEventJunctionMutation,
  deleteUserOrganizationEventJunction as deleteUserOrganizationEventJunctionMutation,
  removeStaffsFromOrganization as removeStaffsFromOrganizationMutationFunction,
  deleteAnOrganization as deleteAnOrganizationMutationFunction,
  createAnOrganization as createAnOrganizationMutationFunction,
  updateAnOrganization as updateAnOrganizationMutationFunction,
  createImage as createImageMutation,
  updateImage as updateImageMutation,
  deleteImage as deleteImageMutation,
  createNewEvent as createNewEventMutation,
} from 'graphql/mutations';
import {
  getUserOrganizationWithRoles,
  getOrganizationDashboardData as getOrganizationDashboardDataQueries,
} from 'graphql/queries';

import {
  eventsByOrganizationIdFilteredEventsStaffId,
  listUserOrganizationWithRoles,
  listUserOrganizationEventJunctions as listUserOrganizationEventJunctionsQueries,
  getOrganization as getOrganizationDataQueries,
} from 'graphql/customQueries';
import { createAlert } from 'modules/alerts/actions';
import { addNewOrganizationToList, removeOrganizationJunction } from 'modules/user/actions';
import { resetSelectedEvent } from 'modules/event/actions';
import { userDataSelector, userOrganizedEventsByOrgIdSelector, userIdSelector } from 'modules/user/selector';
import { setLocalStorageItem, removeLocalStorageItem, roleObjectCreator, getPath, capitalizerOfWords } from 'utils';
import {
  localStorageVariable,
  Status,
  OrganizationRole,
  RoleType,
  ResponseStatus,
  OrganizationStaffRoleType,
  GraphqlSortDirections,
  Routes,
} from 'constants/index';
import {
  selectedOrganizationIdSelector,
  organizationStaffsSelector,
  staffEventListSelector,
  isUserAnOrganizationAdminSelector,
  selectedOrganizationSelector,
} from 'modules/organization/selector';

const logger = new Logger('user/actions');

export const browserHistory = createBrowserHistory({ forceRefresh: true });

export const getOrganization = createAction(GET_ORGANIZATION, async () => async (dispatch, getState) => {
  const state = getState();
  const selectedOrganizationId = selectedOrganizationIdSelector(state);
  const userId = userIdSelector(state);
  try {
    if (!selectedOrganizationId) {
      return;
    }
    dispatch(setIsFetchingOrganization(false));
    const promises = [];

    // 1. Query Organization
    promises.push(
      await fetch.graphql({
        query: getOrganizationDataQueries,
        variables: {
          id: selectedOrganizationId,
        },
      })
    );

    const filter = { organizationId: { eq: selectedOrganizationId }, userId: { eq: userId } };

    // 2. Query User Junction to organization
    promises.push(
      await fetch.graphql({
        query: listUserOrganizationWithRoles,
        variables: {
          filter,
        },
      })
    );

    const promisesResponse = await Promise.all(promises);

    const responseOrg = promisesResponse?.[0];
    const responseJunction = promisesResponse?.[1];

    const getOrganizationDataResponse = responseOrg?.data?.getOrganization;
    const junctionDataResponse = responseJunction?.data?.listUserOrganizationWithRoles?.items?.[0]; // Expected to only have one item
    if (!junctionDataResponse) {
      dispatch(resetSelectedEvent());
      dispatch(resetSelectedOrganization());
      dispatch(push(getPath(Routes.ORGANIZATIONS_DASHBOARD)));
    }
    const isUserAdmin =
      junctionDataResponse?.roles?.some(
        (role) => role?.role === OrganizationStaffRoleType.ADMIN || role?.role === OrganizationStaffRoleType.CREATOR
      ) || false;

    return { organization: getOrganizationDataResponse, isUserAdmin, roles: junctionDataResponse?.roles };
  } catch (err) {
    dispatch(setIsFetchingOrganization(true));
    dispatch(createAlert('CUSTOM_ERROR', `We have trouble fetching your organization data`));
    return null;
  }
});

export const setIsFetchingOrganization = createAction(
  IS_FETCHED_ORGANIZATION_DASHBOARD_DATA,
  async (status) => async () => status
);

export const getUserListOfOrganizations = createAction(GET_USER_LIST_OF_ORGANIZATIONS, async () => async (dispatch) => {
  const promises = [];

  promises.push([]);

  try {
    await Promise.all(promises);
    return true;
  } catch (err) {
    dispatch(createAlert('CustomAlert', `We can't load page as of the moment"`));
    return false;
  }
});

export const getOrganizationStaffList = createAction(
  GET_ORGANIZATION_STAFF_LIST,
  async (addedFilter = {}, filterRemovedUserIds = []) =>
    async (dispatch, getState) => {
      const state = getState();

      const organizationStaffs = organizationStaffsSelector(state);
      const selectedOrganizationId = selectedOrganizationIdSelector(state);
      try {
        if (filterRemovedUserIds.length) {
          return organizationStaffs?.filter((staff) => !filterRemovedUserIds.includes(staff.id));
        }

        if (!selectedOrganizationId?.length) {
          return [];
        }

        // TODO sort by date ascending
        const filter = { organizationId: { eq: selectedOrganizationId }, ...addedFilter };

        const list = await fetch.graphql({
          query: listUserOrganizationWithRoles,
          variables: { filter, limit: 1000 },
        });
        return list?.data?.listUserOrganizationWithRoles?.items || [];
      } catch (err) {
        dispatch(createAlert('CustomAlert', `We can't get list of staffs for this organization as of the moment`));
        return [];
      }
    }
);

export const getOrganizationStaffById = createAction(SET_ORGANIZATION_STAFF, async (id) => async (dispatch) => {
  try {
    dispatch(organizationStaffIsFetched(false));
    dispatch(fetchingOrganizationStaff());
    const staff = await fetch.graphql({
      query: getUserOrganizationWithRoles,
      variables: { id },
    });
    dispatch(organizationStaffIsFetched(true));
    return staff?.data?.getUserOrganizationWithRoles || null;
  } catch (err) {
    dispatch(organizationStaffIsFetched(true));
    dispatch(createAlert('CUSTOM_ERROR', `We can't get this staff as of the moment`));
    return null;
  }
});

export const organizationStaffIsFetched = createAction(ORGANIZATION_STAFF_IS_FETCHED, (value) => () => {
  return value;
});

export const fetchingOrganizationStaff = createAction(FETCHING_ORGANIZATION_STAFF);

export const updateOrganizationStaffData = createAction(UPDATE_STAFF_DETAILS, async (values) => async (dispatch) => {
  try {
    const inputValues = {
      id: values.id,
      roles: [
        roleObjectCreator(
          Object.entries(OrganizationStaffRoleType).find(([key, value]) => value === values?.role)?.[1]
        ),
      ],
    };
    const staff = await fetch.graphql({
      query: updateUserOrganizationWithRolesMutation,
      variables: { input: inputValues },
    });
    dispatch(createAlert('CUSTOM_SUCCESS', 'Successfully updated!'));
    return staff?.data?.updateUserOrganizationWithRoles || null;
  } catch (err) {
    dispatch(organizationStaffIsFetched(true));
    dispatch(createAlert('CUSTOM_ERROR', `We have trouble updating this staff`));
    return null;
  }
});

export const updateStaffEventRole = createAction(
  UPDATE_STAFF_EVENT_ROLE,
  async (id, role, isStaffEventRolUpdate = false) =>
    async (dispatch) => {
      try {
        if (!role || !id) {
          return;
        }

        const roles = [roleObjectCreator(role, true)];
        const inputValues = {
          id,
          roles,
        };
        const staff = await fetch.graphql({
          query: updateUserOrganizationEventJunctionMutation,
          variables: { input: inputValues },
        });
        if (staff?.errors?.length) {
          dispatch(createAlert('CUSTOM_ERROR', `We have trouble updating this staff`));
          return {
            success: false,
          };
        }
        dispatch(createAlert('CUSTOM_SUCCESS', 'Successfully updated!'));
        return { success: true, roles, isStaffEventRolUpdate };
      } catch (err) {
        dispatch(organizationStaffIsFetched(true));
        dispatch(createAlert('CUSTOM_ERROR', `We have trouble updating this staff`));
        return { success: false };
      }
    }
);

export const getStaffEventsList = createAction(
  GET_STAFF_EVENTS_LIST,
  async (addedFilter = {}, filterRemovedEventIds = []) =>
    async (dispatch, getState) => {
      const state = getState();
      try {
        const selectedOrganizationId = selectedOrganizationIdSelector(state);
        const staffEventList = staffEventListSelector(state);

        if (filterRemovedEventIds.length && staffEventList?.length) {
          return staffEventList?.filter((eventJunction) => !filterRemovedEventIds.includes(eventJunction.id));
        }
        const filter = { organizationId: { eq: selectedOrganizationId }, ...addedFilter };

        const userEvents = await fetch.graphql({
          query: listUserOrganizationEventJunctionsQueries,
          variables: { filter, limit: 1000 },
        });
        return userEvents?.data?.listUserOrganizationEventJunctions?.items || [];
      } catch (err) {
        dispatch(organizationStaffIsFetched(true));
        dispatch(createAlert('CUSTOM_ERROR', `We can't get this staff as of the moment`));
        return null;
      }
    }
);

export const setSelectedOrganizationId = createAction(SET_SELECTED_ORGANIZATION_ID, (id) => () => {
  if (!id) {
    return '';
  }
  setLocalStorageItem({ [localStorageVariable.selectedOrganizationId]: id });
  return id;
});

export const resetSelectedOrganization = createAction(RESET_SELECTED_ORGANIZATION, () => (dispatch, getState) => {
  dispatch(removeSelectedOrganizationId());
  return null;
});

export const removeSelectedOrganizationId = createAction(REMOVE_SELECTED_ORGANIZATION_ID, () => () => {
  removeLocalStorageItem(localStorageVariable.selectedOrganizationId);
  return '';
});

export const removeSelectedEventId = createAction(REMOVE_SELECTED_ORGANIZATION_EVENT_ID, () => () => {
  removeLocalStorageItem(localStorageVariable.selectedEventId);
  return '';
});

export const setSelectedEventId = createAction(SET_SELECTED_EVENT_ID, async (id) => async () => {
  if (!id) {
    return '';
  }
  setLocalStorageItem({ [localStorageVariable.selectedEventId]: id });
  return id;
});

export const addUserToAnEvent = createAction(
  ADD_USER_TO_AN_EVENT,
  async (organizationId, userId, eventId) => async () => {
    return null;
  }
);

export const searchOrganizationStaffs = createAction(
  SEARCH_ORGANIZATION_STAFFS,
  async (searchString, searchType = userSearchType.email) =>
    async (dispatch, getState) => {
      const state = getState();

      const selectedOrganizationId = selectedOrganizationIdSelector(state);

      try {
        const filterByName = { fullName: { contains: `${searchString}`.trim().toLowerCase() } };
        const filterByEmail = { userEmail: { eq: `${searchString.trim().toLowerCase()}` } };

        const filter =
          searchType === userSearchType.email ? filterByEmail : searchType === userSearchType.name ? filterByName : {};

        const userResponse = await fetch.graphql({
          query: listUserOrganizationWithRoles,
          variables: { filter: { ...filter, organizationId: { eq: selectedOrganizationId } } },
        });

        return { error: '', list: userResponse?.data?.listUserOrganizationWithRoles ?? [] };
      } catch (error) {
        console.log('Error:', error.message);
        return { error: 'No user found', list: [] };
      }
    }
);

export const addUserToAnOrganization = createAction(
  ADD_USER_TO_AN_ORGANIZATION,
  async (userData = null, shouldTriggerFetchOrganizationList = true) =>
    async (dispatch, getState) => {
      const state = getState();
      const selectedOrganizationId = selectedOrganizationIdSelector(state);
      try {
        if (
          !userData ||
          !selectedOrganizationId ||
          !userData?.email ||
          !userData?.userId ||
          !userData?.firstName ||
          !userData?.lastName
        ) {
          return null;
        }

        const fullName = `${userData?.firstName} ${userData?.lastName}`.toLowerCase();

        const useToStore = {
          userEmail: userData?.email,
          userFirstName: userData?.firstName,
          fullName,
          userLastName: userData?.lastName,
          userId: userData?.userId,
          type: RoleType.ORGANIZATION,
          status: Status.APPROVED,
          organizationId: selectedOrganizationId,
          roles: [roleObjectCreator(userData?.role || OrganizationRole.MEMBER)],
        };

        // 1. Check if this user was not added yet
        const data = await dispatch(getOrganizationStaffList({ userEmail: { eq: `${userData.email}` } }));
        const isExistingWithCurrentOrganization = data?.payload?.length > 0;
        if (isExistingWithCurrentOrganization) {
          dispatch(createAlert('CUSTOM_WARNING', 'This user is already in the organization!'));
          return {
            data: null,
            status: ResponseStatus.ALREADY_CREATED,
          };
        }

        const response = await fetch.graphql({
          query: createUserOrganizationWithRolesMutation,
          variables: { input: useToStore },
        });
        const resData = response?.data?.createUserOrganizationWithRoles || null;
        if (resData && shouldTriggerFetchOrganizationList) {
          dispatch(createAlert('CUSTOM_SUCCESS', 'We successfully added a new staff to your organization'));
          await dispatch(getOrganizationStaffList());
        } else {
          dispatch(createAlert('CustomAlert', `We can't add a new staff for this organization as of the moment"`));
        }

        return {
          data: resData,
          status: ResponseStatus.SUCCESSFULLY_CREATED,
        };
      } catch (err) {
        dispatch(createAlert('CustomAlert', `We can't add a new staff for this organization as of the moment"`));
        return [];
      }
    }
);

export const removeUsersToAnOrganization = createAction(
  REMOVE_USER_TO_AN_ORGANIZATION,
  async (ids = []) =>
    async (dispatch, getState) => {
      const state = getState();

      try {
        if (!ids.length) {
          return {
            success: false,
          };
        }
        const response = await fetch.graphql({
          query: removeStaffsFromOrganizationMutationFunction,
          variables: {
            args: {
              organizationStaffIds: ids,
            },
          },
        });

        const createdDraftRegistration = JSON.parse(response?.data?.removeStaffsFromOrganization);

        await dispatch(getOrganizationStaffList({}, ids));
        dispatch(createAlert('SUCCESS', 'Successfully Remove Staff(s)'));
        return {
          success: true,
        };
      } catch (error) {
        dispatch(createAlert('ERROR', `We can't remove a new staff(s) for this organization as of the moment"`));
        return {
          success: false,
        };
      }
    }
);

export const removeStaffEvents = createAction(
  REMOVE_USER_TO_AN_EVENT,
  async (ids = [], shouldDeleteOnly = false) =>
    async (dispatch) => {
      try {
        if (!ids.length) {
          return {
            success: false,
          };
        }

        const promises = [];

        ids.forEach(async (roleId) => {
          promises.push(
            await fetch.graphql({
              query: deleteUserOrganizationEventJunctionMutation,
              variables: { input: { id: roleId } },
            })
          );
        });

        await Promise.all(promises);

        if (!shouldDeleteOnly) {
          await dispatch(getStaffEventsList({}, ids));
        }
        dispatch(createAlert('SUCCESS', 'Successfully Remove Staff(s) from Event'));
        return {
          success: true,
        };
      } catch (error) {
        dispatch(createAlert('ERROR', `We can't remove event(s) for this staff as of the moment`));
        return {
          success: false,
        };
      }
    }
);

export const updateUserStatusOnSpecificEvent = createAction(
  UPDATE_USER_STATUS_ON_SPECIFIC_EVENT,
  async (profile) => async () => {
    return profile;
  }
);

export const getOrganizationEvents = createAction(
  FETCH_ORGANIZATION_EVENTS,
  async (addedFilter = { sortDirection: GraphqlSortDirections.DESC }) =>
    async (dispatch, getState) => {
      const state = getState();
      dispatch(setIsFetchedOrganizationEvents(false));
      try {
        const selectedOrganizationId = selectedOrganizationIdSelector(state);
        const userId = userIdSelector(state);
        const isUserAdmin = isUserAnOrganizationAdminSelector(state);
        const userOrganizedEventsInOrganization = userOrganizedEventsByOrgIdSelector(selectedOrganizationId)(state);

        const input = { organizationId: selectedOrganizationId, ...addedFilter };

        const orgEventsResponse = await fetch.graphql({
          query: eventsByOrganizationIdFilteredEventsStaffId(userId),
          variables: { ...input, limit: 1000 },
        });
        dispatch(setIsFetchedOrganizationEvents(true));
        const events = orgEventsResponse?.data?.eventsByOrganizationId?.items || [];

        // if user is org admin then return all events
        if (isUserAdmin) {
          return events;
        }

        // Show only events user is added to this organization
        return events?.filter(
          (event) => event?.eventStaffs?.items?.length // When there's a value means user is part of the event
        );
      } catch (err) {
        dispatch(setIsFetchedOrganizationEvents(true));
        dispatch(createAlert('CUSTOM_ERROR', `We have trouble fetching your organization events`));
        return null;
      }
    }
);

export const createOrganization = createAction(
  CREATE_ORGANIZATION,
  async (organizationName) => async (dispatch, getState) => {
    const state = getState();
    try {
      const userData = userDataSelector(state);

      const args = {
        organizationName,
        creatorId: userData.id,
        userFirstName: userData.firstName,
        userLastName: userData.lastName,
        userEmail: userData.email,
      };

      const response = await fetch.graphql({
        query: createAnOrganizationMutationFunction,
        variables: {
          args,
        },
      });

      const createAnOrganizationResponse = JSON.parse(response?.data?.createAnOrganization);

      if (createAnOrganizationResponse?.success) {
        dispatch(addNewOrganizationToList(createAnOrganizationResponse?.data?.createUserOrganizationWithRoles));

        const organizationId = createAnOrganizationResponse?.data?.createUserOrganizationWithRoles?.organization?.id;
        if (organizationId) {
          dispatch(setSelectedOrganizationId(organizationId));
          // dispatch(addToUserOrganizationList(createAnOrganizationResponse?.data?.createUserOrganizationWithRoles));
        }
        dispatch(createAlert('CUSTOM_SUCCESS', `We successfully create your new organization`));
        return organizationId || null;
      }

      dispatch(createAlert('CUSTOM_ERROR', `We have trouble creating your organization`));
      return null;
    } catch (err) {
      dispatch(createAlert('CUSTOM_ERROR', `We have trouble creating your organization`));
      return null;
    }
  }
);

export const setIsFetchedOrganizationEvents = createAction(
  SET_IS_FETCHED_ORGANIZATION_EVENTS,
  async (status) => () => status
);

export const getOrganizationDashboardData = createAction(
  FETCH_ORGANIZATION_DASHBOARD_DATA,
  async () => async (dispatch, getState) => {
    const state = getState();
    const selectedOrganizationId = selectedOrganizationIdSelector(state);

    try {
      if (!selectedOrganizationId) {
        dispatch(resetSelectedOrganization());
        return;
      }

      dispatch(setIsFetchedOrganizationDashboardData(false));
      const response = await fetch.graphql({
        query: getOrganizationDashboardDataQueries,
        variables: {
          id: selectedOrganizationId,
        },
      });

      const getOrganizationDashboardDataResponse = JSON.parse(response?.data?.getOrganizationDashboardData);
      return getOrganizationDashboardDataResponse;
    } catch (err) {
      dispatch(setIsFetchedOrganizationDashboardData(true));
      dispatch(createAlert('CUSTOM_ERROR', `We have trouble fetching your organization data`));
      return null;
    }
  }
);

export const setIsFetchedOrganizationDashboardData = createAction(
  IS_FETCHED_ORGANIZATION_DASHBOARD_DATA,
  async (status) => async () => status
);

export const deleteOrganization = createAction(
  DELETE_ORGANIZATION,
  async (organizationId, junctionId, creatorId) => async (dispatch, getState) => {
    const state = getState();
    try {
      const userData = userDataSelector(state);

      const args = [
        {
          organizationId,
          junctionId,
          creatorId,
          userId: userData.id,
        },
      ];

      const response = await fetch.graphql({
        query: deleteAnOrganizationMutationFunction,
        variables: {
          args,
        },
      });

      const deleteAnOrganizationResponse = JSON.parse(response?.data?.deleteAnOrganization);

      if (!deleteAnOrganizationResponse?.some((response) => !response?.success)) {
        dispatch(removeOrganizationJunction(junctionId));
        dispatch(createAlert('CUSTOM_SUCCESS', `We successfully deleted an organization`));
        return true;
      }

      dispatch(createAlert('CUSTOM_ERROR', `We're having trouble deleting your organization`));
      return false;
    } catch (err) {
      dispatch(createAlert('CUSTOM_ERROR', `We're having trouble deleting your organization`));
      return false;
    }
  }
);

export const upsertDeleteImageLink = createAction(
  UPDATE_IMAGE_LINK,
  async (options, isDeleteLink = false) =>
    async (dispatch) => {
      try {
        if (((!options?.belongsTo || !options?.urlImage) && !isDeleteLink) || (isDeleteLink && !options?.id)) {
          return;
        }

        const primaryKey = options?.primaryKey || '';

        const inputValues = {
          ...(options?.id && { id: options?.id }),
          belongsTo: options?.belongsTo || '',
          urlImage: options?.urlImage,
        };

        const mutationType = inputValues?.id ? updateImageMutation : createImageMutation;

        const params = isDeleteLink
          ? {
              query: deleteImageMutation,
              variables: {
                input: { id: options?.id },
              },
            }
          : {
              query: mutationType,
              variables: {
                input: {
                  ...inputValues,
                  ...(primaryKey && { id: primaryKey }), // if no id for inputValues but it has primary then assign an id to it but for create Image setup
                },
              },
            };

        const response = await fetch.graphql(params);

        if (!response?.errors?.length) {
          if (isDeleteLink) {
            dispatch(createAlert('CUSTOM_SUCCESS', `Delete Success!`));
          } else {
            dispatch(
              createAlert('CUSTOM_SUCCESS', `We have success ${inputValues?.id ? 'updating' : 'creating'} image link`)
            );
          }
        } else {
          dispatch(createAlert('CUSTOM_ERROR', `We have an error creating image link`));
        }

        return true;
      } catch (err) {
        dispatch(createAlert('CUSTOM_ERROR', `We have an error updating image link`));
        return false;
      }
    }
);

export const updateOrganizationData = createAction(
  UPDATE_ORGANIZATION_DATA,
  async (organizationData) => async (dispatch) => {
    dispatch(setUpdatingOrganization(true));
    try {
      if (!organizationData?.id) {
        console.log('organization data id must be provided');
        return false;
      }

      const args = { id: organizationData?.id, organizationUpdatedDataValues: JSON.stringify(organizationData) };

      const response = await fetch.graphql({
        query: updateAnOrganizationMutationFunction,
        variables: { args },
      });

      const updateAnOrganizationResponse = JSON.parse(response?.data?.updateAnOrganization);

      dispatch(setUpdatingOrganization(false));
      dispatch(createAlert('CUSTOM_SUCCESS', `We're successfully your organization data`));
      return updateAnOrganizationResponse;
    } catch (error) {
      dispatch(createAlert('CUSTOM_ERROR', `We're having trouble updating your organization data`));
      dispatch(setUpdatingOrganization(false));
      return false;
    }
  }
);

export const updateAnEvent = createAction(
  DELETE_ORGANIZATION,
  async (organizationId, junctionId, creatorId) => async (dispatch, getState) => {
    const state = getState();
    try {
      const userData = userDataSelector(state);

      const args = [
        {
          organizationId,
          junctionId,
          creatorId,
          userId: userData.id,
        },
      ];

      const response = await fetch.graphql({
        query: deleteAnOrganizationMutationFunction,
        variables: {
          args,
        },
      });

      const deleteAnOrganizationResponse = JSON.parse(response?.data?.deleteAnOrganization);

      if (!deleteAnOrganizationResponse?.some((response) => !response?.success)) {
        dispatch(removeOrganizationJunction(junctionId));
        dispatch(createAlert('CUSTOM_SUCCESS', `We successfully deleted an organization`));
        return true;
      }

      dispatch(createAlert('CUSTOM_ERROR', `We're having trouble deleting your organization`));
      return false;
    } catch (err) {
      dispatch(createAlert('CUSTOM_ERROR', `We're having trouble deleting your organization`));
      return false;
    }
  }
);

export const createNewEvent = createAction(CREATE_NEW_EVENT, async (eventData) => async (dispatch, getState) => {
  const state = getState();
  try {
    const organizationId = selectedOrganizationIdSelector(state);
    const userData = userDataSelector(state);

    const args = {
      organizationId,
      userId: userData.id,
      eventData: JSON.stringify(eventData),
      userData: JSON.stringify(userData),
    };

    const response = await fetch.graphql({
      query: createNewEventMutation,
      variables: {
        args,
      },
    });

    const createNewEventResponse = JSON.parse(response?.data?.createNewEvent);
    if (!createNewEventResponse?.errors?.length && createNewEventResponse?.success) {
      const eventId = createNewEventResponse?.data?.dynamoData?.id;
      dispatch(addEventOrganizationEventsList(createNewEventResponse?.data?.dynamoData));
      dispatch(createAlert('CUSTOM_SUCCESS', `We successfully initialize your event.`));
      return { eventId };
    }

    dispatch(createAlert('CUSTOM_ERROR', `We're having trouble create your new event`));
    return { eventId: '' };
  } catch (err) {
    console.log('err:', err);
    dispatch(createAlert('CUSTOM_ERROR', `We're having trouble create your new event`));
    return false;
  }
});

export const addEventOrganizationEventsList = createAction(ADD_EVENT_TO_EVENT_LISTS, (event) => () => event);

export const setUpdatingOrganization = createAction(SET_UPDATING_ORGANIZATION, (status) => () => status);
