export const listApps = /* GraphQL */ `
  query ListApps($filter: ModelAppFilterInput, $limit: Int, $nextToken: String) {
    listApps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        featureToggles
        constants
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventsByOrganizationIdFilteredEventsStaffId = (staffId) => /* GraphQL */ `
  query EventsByOrganizationId(
    $organizationId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByOrganizationId(
      organizationId: $organizationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventName
        dateOfEvent
        registrationEnd
        eventShortDescription
        organizationId
        organization {
          id
          creatorId
          organizationName
          status
          createdAt
          updatedAt
        }
        eventStaffs(filter: {userId: {eq: "${staffId}"}}) {
        items {
          id
          userEmail
          userId
        }
          nextToken
        }
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        routeImageURL {
          nextToken
        }
        mainEventImage {
          items {
            id
            belongsTo
            description
            title
            urlImage
          }
          nextToken
        }
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        eventPrizes {
          nextToken
        }
        categories {
          items {
            slots {
              totalSlotsAvailable
              totalRegistrants
            }
            id
            eventId
            distance
          }
          nextToken
        }
        contactInfo {
          type
          value
        }
        discounts {
          nextToken
        }
        published
        odoo {
          odooId
          odooExternalId
          data
        }
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        deliveryOptions {
          title
          description
          value
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userSub
        firstName
        lastName
        password
        email
        isEmailConfirmed
        birthDate
        subscription {
          organizations
        }
        organizedEvents(limit: 1000) {
          items {
            id
            userId
            eventId
            organizationId
            status
            roles {
              role
              scope
              descriptions
            }
          }
          nextToken
        }
        organization(limit: 1000) {
          items {
            id
            type
            userId
            organizationId
            organization {
              id
              organizationName
              status
              creatorId
              organizationProfilePhotoURLs {
                items {
                  id
                  belongsTo
                  description
                  title
                  urlImage
                  createdAt
                  updatedAt
                }
              }
              events {
                items {
                  id
                }
                nextToken
              }
            }
            user {
              firstName
              id
              lastName
            }
            status
            roles {
              role
              scope
              descriptions
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        registrations {
          nextToken
        }
        phoneNumber
        about
        isPublic
        profilePhoto {
          id
          belongsTo
          description
          title
          urlImage
          createdAt
          updatedAt
        }
        odoo {
          odooId
          odooExternalId
          data
        }
        primaryAddress {
          type
          addressLine1
          country
          region
          regionCode
          state
          stateCode
          city
          cityCode
          barangay
          barangayCode
          zipCode
        }
        billingShippingAddress {
          type
          addressLine1
          country
          region
          regionCode
          state
          stateCode
          city
          cityCode
          barangay
          barangayCode
          zipCode
        }
        addresses {
          type
          addressLine1
          country
          region
          regionCode
          state
          stateCode
          city
          cityCode
          barangay
          barangayCode
          zipCode
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      eventName
      eventShortDescription
      organizationId
      organization {
        id
        creatorId
        organizationEmails {
          items {
            id
            belongsTo
            type
            email
            verified
          }
          nextToken
        }
        organizationProfilePhotoURLs {
          items {
            id
            belongsTo
            description
            title
            urlImage
          }
          nextToken
        }
        organizationName
        odoo {
          odooId
          odooExternalId
          data
        }
        staffs {
          nextToken
        }
        events {
          nextToken
        }
        status
        createdAt
        updatedAt
      }
      eventStaffs {
        items {
          id
          userId
          userEmail
          eventId
          organizationId
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      registrationEnd
      registrationStart
      hasSameRouteForAllCat
      routeImageURL {
        items {
          id
          belongsTo
          description
          title
          urlImage
          createdAt
          updatedAt
        }
        nextToken
      }
      mainEventImage {
        items {
          id
          belongsTo
          description
          title
          urlImage
          createdAt
          updatedAt
        }
        nextToken
      }
      payoutOption {
        bankName
        accountNumber
        fullName
      }
      eventInstance
      eventType
      dateOfEvent
      slotsAvailabilityIsSameForAllCategories
      canHaveMultipleRegistrantsInOneOrder
      hasSameFormForAllCategories
      metricUsedIsKm
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      eventPrizes {
        items {
          id
          eventId
          prizeType
          imageURL {
            items {
              id
              belongsTo
              description
              title
              urlImage
            }
            nextToken
          }
          prizeValue {
            prizeAmountType
            prizeAmountValue
          }
          prizeCategory {
            prizeCategoryType
            prizeCategoryValue
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      categories(limit: 500) {
        items {
          id
          eventId
          distance
          categoryType
          categoryValue
          cutOffTime
          allowableQuantityPerOrder
          assemblyTime
          gunTime
          event {
            id
            eventName
            eventShortDescription
            organizationId
            registrationEnd
            registrationStart
            hasSameRouteForAllCat
            eventInstance
            eventType
            dateOfEvent
            slotsAvailabilityIsSameForAllCategories
            canHaveMultipleRegistrantsInOneOrder
            hasSameFormForAllCategories
            metricUsedIsKm
            published
            isWaiverFormSameForAll
            isRulesAndRegulationsSameForAll
            addressLine1
            addressLine2
            barangay
            city
            state
            region
            country
            createdAt
            updatedAt
          }
          form(limit: 100) {
            items {
              id
              eventId
              formOrderNumber
              categoryId
              formFields {
                defaultValue
                fieldId
                fieldType
                label
                options {
                  label
                  value
                }
                orderNumber
                placeholder
                type
                validationType
                validations {
                  params
                  type
                }
              }
              updatedAt
            }
            nextToken
          }
          categoryImageURL {
            items {
              id
              belongsTo
              description
              title
              urlImage
              createdAt
              updatedAt
            }
            nextToken
          }
          slots {
            categoryId
            eventId
            totalSlotsAvailable
            totalRegistrants
            createdAt
            updatedAt
          }
          raceKits {
            items {
              id
              categoryId
              eventId
              kitType
              description
              optionName
              optionValues
              images {
                items {
                  id
                  belongsTo
                  description
                  title
                  urlImage
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          cutOffTime
          allowableQuantityPerOrder
          assemblyTime
          gunTime
          regPrice {
            categoryId
            eventId
            prices {
              type
              amountInCent
              currency
              enabled
              priceValidFrom
              priceValidTo
            }
            createdAt
            updatedAt
          }
          categoryPrizes {
            prizeValue {
              prizeAmountType
              prizeAmountValue
            }
            prizeCategory {
              prizeCategoryType
              prizeCategoryValue
            }
          }
          odoo {
            odooId
            odooExternalId
            data
            __typename
          }
          routeImageURL {
            items {
              id
              belongsTo
              description
              title
              urlImage
              createdAt
              updatedAt
            }
            nextToken
          }

          waiverForm(limit: 500) {
            items {
              id
              eventId
              belongsTo
              type
              title
              description
              body
              createdAt
              updatedAt
            }
            nextToken
          }
          rulesAndRegulations(limit: 500) {
            items {
              id
              eventId
              belongsTo
              type
              title
              description
              body
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      contactInfo {
        type
        value
      }
      published
      isWaiverFormSameForAll
      isRulesAndRegulationsSameForAll
      addressLine1
      addressLine2
      barangay
      city
      state
      region
      country
      deliveryOptions {
        title
        description
        value
      }
      createdAt
      updatedAt
    }
  }
`;

export const listEvents = /* GraphQL */ `
  query ListEvents($filter: ModelEventFilterInput, $limit: Int, $nextToken: String) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventName
        eventShortDescription
        organizationId
        subscription {
          eventsStaff
        }
        organization {
          id
          creatorId
          organizationName
          organizationProfilePhotoURLs {
            items {
              id
              belongsTo
              description
              title
              urlImage
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          createdAt
          updatedAt
        }
        eventStaffs {
          nextToken
        }
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        routeImageURL {
          nextToken
        }
        mainEventImage {
          items {
            id
            belongsTo
            description
            title
            urlImage
          }
          nextToken
        }
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        eventPrizes {
          nextToken
        }
        categories {
          items {
            id
            eventId
            distance
            categoryType
            categoryValue
            cutOffTime
            allowableQuantityPerOrder
            assemblyTime
            gunTime
            form {
              items {
                eventId
                formOrderNumber
                categoryId
                formFields {
                  defaultValue
                  fieldId
                  fieldType
                  label
                  options {
                    label
                    value
                  }
                  orderNumber
                  placeholder
                  type
                  validationType
                  validations {
                    params
                    type
                  }
                }
              }
              nextToken
            }
            regPrice {
              categoryId
              eventId
              prices {
                type
                amountInCent
                currency
                enabled
                priceValidFrom
                priceValidTo
              }
            }
            slots {
              totalRegistrants
              totalSlotsAvailable
            }
          }
          nextToken
        }
        contactInfo {
          type
          value
        }
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        deliveryOptions {
          title
          description
          value
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listUserOrganizationEventJunctions = /* GraphQL */ `
  query ListUserOrganizationEventJunctions(
    $filter: ModelUserOrganizationEventJunctionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserOrganizationEventJunctions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        userEmail
        user {
          id
          userSub
          firstName
          lastName
          email
          isEmailConfirmed
          birthDate
          phoneNumber
          about
          profilePhoto {
            id
            belongsTo
            description
            title
            urlImage
            createdAt
            updatedAt
          }
          isPublic
          createdAt
          updatedAt
        }
        eventId
        event {
          id
          eventName
          eventShortDescription
          organizationId
          registrationEnd
          registrationStart
          hasSameRouteForAllCat
          eventInstance
          eventType
          dateOfEvent
          slotsAvailabilityIsSameForAllCategories
          canHaveMultipleRegistrantsInOneOrder
          hasSameFormForAllCategories
          metricUsedIsKm
          published
          isWaiverFormSameForAll
          isRulesAndRegulationsSameForAll
          addressLine1
          addressLine2
          barangay
          city
          state
          region
          country
          createdAt
          updatedAt
        }
        organizationId
        status
        roles {
          role
          scope
          descriptions
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      creatorId
      organizationEmails {
        items {
          id
          belongsTo
          type
          email
          verified
          createdAt
          updatedAt
        }
        nextToken
      }
      organizationProfilePhotoURLs {
        items {
          id
          belongsTo
          description
          title
          urlImage
          createdAt
          updatedAt
        }
        nextToken
      }
      organizationName
      odoo {
        odooId
        odooExternalId
        data
      }
      staffs {
        items {
          id
          type
          userId
          userEmail
          userFirstName
          userLastName
          organizationId
          status
          roles {
            role
            scope
            descriptions
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      subscription {
        organizationStaff
        events
      }
      events {
        items {
          id
          eventName
          eventShortDescription
          organizationId
          registrationEnd
          registrationStart
          hasSameRouteForAllCat
          eventInstance
          eventType
          dateOfEvent
          slotsAvailabilityIsSameForAllCategories
          canHaveMultipleRegistrantsInOneOrder
          hasSameFormForAllCategories
          metricUsedIsKm
          published
          isWaiverFormSameForAll
          isRulesAndRegulationsSameForAll
          addressLine1
          addressLine2
          barangay
          city
          state
          region
          country
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      createdAt
      updatedAt
    }
  }
`;

export const listUserOrganizationWithRoles = /* GraphQL */ `
  query ListUserOrganizationWithRoles(
    $filter: ModelUserOrganizationWithRolesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserOrganizationWithRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        userId
        userEmail
        userFirstName
        userLastName
        user {
          id
          userSub
          firstName
          lastName
          email
          isEmailConfirmed
          birthDate
          profilePhoto {
            id
            belongsTo
            description
            title
            urlImage
            createdAt
            updatedAt
          }
          phoneNumber
          about
          isPublic
          createdAt
          updatedAt
        }
        organizationId
        organization {
          id
          creatorId
          organizationName
          status
          createdAt
          updatedAt
        }
        status
        roles {
          role
          scope
          descriptions
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getEventRegistrationOrder = /* GraphQL */ `
  query GetEventRegistrationOrder($id: ID!) {
    getEventRegistrationOrder(id: $id) {
      id
      userEmail
      eventId
      userId
      user {
        id
        userSub
        firstName
        lastName
        email
        isEmailConfirmed
        birthDate
        organizedEvents {
          nextToken
        }
        organization {
          nextToken
        }
        registrations {
          nextToken
        }
        phoneNumber
        about
        isPublic
        profilePhoto {
          id
          belongsTo
          description
          title
          urlImage
          createdAt
          updatedAt
        }
        odoo {
          odooId
          odooExternalId
          data
        }
        primaryAddress {
          type
          addressLine1
          country
          region
          regionCode
          state
          stateCode
          city
          cityCode
          barangay
          barangayCode
          zipCode
        }
        billingShippingAddress {
          type
          addressLine1
          country
          region
          regionCode
          state
          stateCode
          city
          cityCode
          barangay
          barangayCode
          zipCode
        }
        addresses {
          type
          addressLine1
          country
          region
          regionCode
          state
          stateCode
          city
          cityCode
          barangay
          barangayCode
          zipCode
        }
        createdAt
        updatedAt
      }
      lineItems {
        items {
          category {
            distance
            slots {
              totalSlotsAvailable
              totalRegistrants
            }
            form {
              items {
                eventId
                formOrderNumber
                categoryId
                formFields {
                  defaultValue
                  fieldId
                  fieldType
                  label
                  options {
                    label
                    value
                  }
                  orderNumber
                  placeholder
                  type
                  validationType
                  validations {
                    params
                    type
                  }
                }
              }
              nextToken
            }
            event {
              metricUsedIsKm
              eventName
              dateOfEvent
              registrationEnd
              registrationStart
            }
          }
          registrantData {
            items {
              id
              categoryId
              userEmail
              lineItemId
              userData
              orderId
              eventId
              formOrderNumber
              bibNumber
            }
            nextToken
          }
          teamName
          orderId
          categoryId
          id
          eventId
        }
        nextToken
      }
      registrationState
      paymentStatus
      progress
      deliveryOption
      paymentInformation {
        orderId
        source {
          id
          type
        }
        billing {
          email
          name
          phone
        }
        description
        paidAt
        totalPaidAmount {
          type
          amountInCent
          currency
        }
        breakDown {
          id
          distance
          quantity
        }
        netAmount {
          type
          amountInCent
          currency
        }
        taxes {
          type
          amountInCent
          currency
        }
        fees {
          type
          amountInCent
          currency
        }
        donations {
          type
          amountInCent
          currency
        }
        discounts {
          type
          amountInCent
          currency
        }
        createdAt
        updatedAt
      }
      appliedDiscounts {
        discountId
      }
      paymentRetries {
        sourceHookId
        timeStamp
      }
      createdAt
      updatedAt
    }
  }
`;

export const listEventRegistrationOrders = /* GraphQL */ `
  query ListEventRegistrationOrders($filter: ModelEventRegistrationOrderFilterInput, $limit: Int, $nextToken: String) {
    listEventRegistrationOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userEmail
        eventId
        event {
          id
          metricUsedIsKm
          eventName
          dateOfEvent
          registrationEnd
          registrationStart
          categories(limit: 100) {
            items {
              id
              slots {
                totalSlotsAvailable
                totalRegistrants
              }
            }
          }
          slotsAvailabilityIsSameForAllCategories
        }
        userId
        user {
          id
          userSub
          firstName
          lastName
          email
          isEmailConfirmed
          birthDate
          phoneNumber
          about
          isPublic
          createdAt
          updatedAt
        }
        progress
        lineItems {
          items {
            category {
              distance
              slots {
                totalSlotsAvailable
                totalRegistrants
              }
              form {
                items {
                  eventId
                  formOrderNumber
                  categoryId
                  formFields {
                    defaultValue
                    fieldId
                    fieldType
                    label
                    options {
                      label
                      value
                    }
                    orderNumber
                    placeholder
                    type
                    validationType
                    validations {
                      params
                      type
                    }
                  }
                }
                nextToken
              }
            }
            registrantData {
              items {
                id
                categoryId
                userEmail
                lineItemId
                userData
                orderId
                eventId
                bibNumber
                formOrderNumber
              }
              nextToken
            }
            teamName
            orderId
            categoryId
            id
            eventId
          }
          nextToken
        }
        registrationState
        paymentStatus
        progress
        deliveryOption
        paymentInformation {
          orderId
          description
          billing {
            address {
              city
              country
              line1
              postal_code
              state
              line2
            }
            email
            name
            phone
          }
          odoo {
            odooId
            odooExternalId
            data
          }
          paidAt
          createdAt
          updatedAt
        }
        appliedDiscounts {
          discountId
        }
        paymentRetries {
          sourceHookId
          timeStamp
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const registrationByUserId = /* GraphQL */ `
  query RegistrationByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventRegistrationOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userEmail
        eventId
        event {
          id
          metricUsedIsKm
          eventName
          dateOfEvent
          registrationEnd
          registrationStart
          categories(limit: 100) {
            items {
              id
              slots {
                totalSlotsAvailable
                totalRegistrants
              }
            }
          }
          slotsAvailabilityIsSameForAllCategories
        }
        userId
        user {
          id
          userSub
          firstName
          lastName
          email
          isEmailConfirmed
          birthDate
          phoneNumber
          about
          isPublic
          createdAt
          updatedAt
        }
        progress
        lineItems {
          items {
            category {
              distance
              slots {
                totalSlotsAvailable
                totalRegistrants
              }
              form {
                items {
                  eventId
                  formOrderNumber
                  categoryId
                  formFields {
                    defaultValue
                    fieldId
                    fieldType
                    label
                    options {
                      label
                      value
                    }
                    orderNumber
                    placeholder
                    type
                    validationType
                    validations {
                      params
                      type
                    }
                  }
                }
                nextToken
              }
            }
            registrantData {
              items {
                id
                categoryId
                userEmail
                lineItemId
                userData
                orderId
                eventId
                bibNumber
                formOrderNumber
              }
              nextToken
            }
            teamName
            orderId
            categoryId
            id
            eventId
          }
          nextToken
        }
        registrationState
        paymentStatus
        progress
        deliveryOption
        paymentInformation {
          orderId
          description
          billing {
            address {
              city
              country
              line1
              postal_code
              state
              line2
            }
            email
            name
            phone
          }
          odoo {
            odooId
            odooExternalId
            data
          }
          paidAt
          createdAt
          updatedAt
        }
        appliedDiscounts {
          discountId
        }
        paymentRetries {
          sourceHookId
          timeStamp
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const registrantsByEvent = /* GraphQL */ `
  query RegistrantsByEvent(
    $eventId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrantDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrantsByEvent(
      eventId: $eventId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lineItemId
        orderId
        eventId
        formOrderNumber
        categoryId
        lineItem {
          id
          registrationOrder {
            id
            userEmail
            registrationState
            paymentStatus
            progress
            deliveryOption
            paymentStatus
          }
          category {
            distance
            categoryType
            categoryValue
            event {
              metricUsedIsKm
            }
          }
          teamName
          createdAt
          updatedAt
        }
        firstName
        lastName
        fullName
        bibNumber
        userEmail
        gender
        userData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const registrantDataByOrder = /* GraphQL */ `
  query RegistrantDataByOrder(
    $orderId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrantDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrantDataByOrder(
      orderId: $orderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lineItemId
        orderId
        eventId
        formOrderNumber
        categoryId
        lineItem {
          id
          orderId
          category {
            event {
              metricUsedIsKm
            }
            distance
          }
          eventId
          categoryId
          teamName
          createdAt
          updatedAt
          __typename
        }
        firstName
        lastName
        fullName
        age
        bibNumber
        userEmail
        gender
        userData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const eventsByOrganizationId = /* GraphQL */ `
  query EventsByOrganizationId(
    $organizationId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByOrganizationId(
      organizationId: $organizationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventName
        eventShortDescription
        organizationId
        organization {
          id
          creatorId
          organizationName
          status
          createdAt
          updatedAt
        }
        eventStaffs {
          nextToken
        }
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        routeImageURL {
          nextToken
        }
        mainEventImage {
          items {
            id
            belongsTo
            description
            title
            urlImage
          }
          nextToken
        }
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        eventPrizes {
          nextToken
        }
        categories {
          items {
            slots {
              totalSlotsAvailable
              totalRegistrants
            }
            id
            eventId
            distance
          }
          nextToken
        }
        contactInfo {
          type
          value
        }
        discounts {
          nextToken
        }
        published
        odoo {
          odooId
          odooExternalId
          data
        }
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        payoutOption {
          bankName
          accountNumber
          fullName
        }
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        deliveryOptions {
          title
          description
          value
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listEventCategories = /* GraphQL */ `
  query ListEventCategories($filter: ModelEventCategoryFilterInput, $limit: Int, $nextToken: String) {
    listEventCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        waiverForm {
          items {
            id
            eventId
            belongsTo
            type
            title
            description
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        rulesAndRegulations {
          items {
            id
            eventId
            belongsTo
            type
            title
            description
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        event {
          id
          eventName
          eventShortDescription
          organizationId
          registrationEnd
          registrationStart
          hasSameRouteForAllCat
          eventInstance
          eventType
          dateOfEvent
          slotsAvailabilityIsSameForAllCategories
          canHaveMultipleRegistrantsInOneOrder
          hasSameFormForAllCategories
          metricUsedIsKm
          published
          isWaiverFormSameForAll
          isRulesAndRegulationsSameForAll
          addressLine1
          addressLine2
          barangay
          city
          state
          region
          country
          createdAt
          updatedAt
        }
        form {
          items {
            id
            eventId
            formOrderNumber
            formFields {
              defaultValue
              fieldId
              fieldType
              label
              options {
                label
                value
              }
              orderNumber
              placeholder
              type
              validationType
              validations {
                params
                type
              }
            }
          }
          nextToken
        }
        categoryImageURL {
          items {
            id
            belongsTo
            description
            title
            urlImage
            createdAt
            updatedAt
          }
          nextToken
        }
        slots {
          categoryId
          eventId
          totalSlotsAvailable
          totalRegistrants
          createdAt
          updatedAt
        }
        raceKits {
          items {
            id
            categoryId
            eventId
            kitType
            description
            optionName
            optionValues
            images {
              items {
                id
                belongsTo
                description
                title
                urlImage
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        regPrice {
          categoryId
          eventId
          prices {
            type
            amountInCent
            currency
            enabled
            priceValidFrom
            priceValidTo
          }
          createdAt
          updatedAt
        }
        categoryPrizes {
          prizeValue {
            prizeAmountType
            prizeAmountValue
          }
          prizeCategory {
            prizeCategoryType
            prizeCategoryValue
          }
        }
        routeImageURL {
          items {
            id
            belongsTo
            description
            title
            urlImage
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getEventCategory = /* GraphQL */ `
  query GetEventCategory($id: ID!) {
    getEventCategory(id: $id) {
      id
      eventId
      distance
      event {
        id
        eventName
        eventShortDescription
        organizationId
        organization {
          id
          creatorId
          organizationName
          status
          createdAt
          updatedAt
        }
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        odoo {
          odooId
          odooExternalId
          data
        }
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        payoutOption {
          bankName
          accountNumber
          fullName
        }
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        deliveryOptions {
          title
          description
          value
        }
        updatedBibNumberSequence
        createdAt
        updatedAt
      }
      categoryType
      categoryValue
      categoryImageURL {
        items {
          id
          belongsTo
          description
          title
          urlImage
          createdAt
          updatedAt
        }
        nextToken
      }
      slots {
        categoryId
        eventId
        totalSlotsAvailable
        totalRegistrants
        createdAt
        updatedAt
      }
      raceKits {
        items {
          id
          categoryId
          eventId
          kitType
          description
          displaySequence
          optionName
          optionValues
          images {
            items {
              id
              belongsTo
              description
              title
              urlImage
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      cutOffTime
      allowableQuantityPerOrder
      assemblyTime
      gunTime
      regPrice {
        categoryId
        eventId
        category {
          id
          eventId
          distance
          categoryType
          categoryValue
          cutOffTime
          allowableQuantityPerOrder
          assemblyTime
          gunTime
          createdAt
          updatedAt
        }
        prices {
          type
          amountInCent
          currency
          enabled
          priceValidFrom
          priceValidTo
        }
        createdAt
        updatedAt
      }
      categoryPrizes {
        prizeValue {
          prizeAmountType
          prizeAmountValue
        }
        prizeCategory {
          prizeCategoryType
          prizeCategoryValue
        }
      }
      form(limit: 100) {
        items {
          id
          eventId
          formOrderNumber
          categoryId
          formFields {
            defaultValue
            fieldId
            fieldType
            label
            options {
              label
              value
            }
            orderNumber
            placeholder
            type
            validationType
            validations {
              params
              type
            }
          }
        }
        nextToken
      }
      registrants(filter: { bibNumber: { attributeExists: true } }) {
        items {
          id
          orderId
          eventId
          categoryId
          teamName
          createdAt
          updatedAt
        }
        nextToken
      }
      routeImageURL {
        items {
          id
          belongsTo
          description
          title
          urlImage
          createdAt
          updatedAt
        }
        nextToken
      }
      waiverForm {
        items {
          id
          eventId
          belongsTo
          type
          title
          description
          body
          createdAt
          updatedAt
        }
        nextToken
      }
      rulesAndRegulations {
        items {
          id
          eventId
          belongsTo
          type
          title
          description
          body
          createdAt
          updatedAt
        }
        nextToken
      }
      odoo {
        odooId
        odooExternalId
        data
      }
      raceBibPreFix
      createdAt
      updatedAt
    }
  }
`;

export const lineItemByOrder = /* GraphQL */ `
  query LineItemByOrder(
    $orderId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventRegistrationLineItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lineItemByOrder(
      orderId: $orderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        eventId
        categoryId
        teamName
        registrantData(limit: 1000) {
          items {
            id
            lineItemId
            orderId
            eventId
            formOrderNumber
            categoryId
            firstName
            lastName
            fullName
            age
            bibNumber
            userEmail
            gender
            userData
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const organizationEventJunctionByUser = /* GraphQL */ `
  query OrganizationEventJunctionByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserOrganizationEventJunctionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationEventJunctionByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userEmail
        eventId
        organizationId
        status
        createdAt
        roles {
          role
          scope
          descriptions
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
