export const NeedsCTXQueries = [
  'createToken',
  'verifyToken',
  'upsertUser',
  'addUserToGroup',
  'createDraftRegistration',
  'resetDraftRegistration',
  'getCalculatedRegistrationCart',
  'updateCategoriesInventory',
  'updateAmountAccumulated',
  'preCheckoutChecker',
  'createAnOrganization',
  'deleteAnOrganization',
  'updateAnOrganization',
  'removeStaffsFromOrganization',
  'batchDeleteUserOrganizationEventJunction',
  'createNewEvent',
  'updateAnEvent',
  'deleteAnEvent',
  'createNewCategory',
  'updateCategory',
  'deleteCategory',

  // Queries
  'getOrganizationDashboardData',
  'fetchEventPortalDashboardData',
];

export const PriceType = {
  REGULAR_PRICE: 'Regular Price',
};

export const AMPLIFY_ENVIRONMENT = {
  DEVELOP: 'develop',
  PRODUCTION: 'production',
  TESTING: 'testing',
};

export const ReturnType = {
  SortedNameValue: 'SortedNameValue',
  ValueOnlyWithCurrencyFormat: 'ValueOnlyWithCurrencyFormat',
};

export const FeeType = {
  SERVICE_FEE: 'service-fee',
  TRANSACTION_FEE: 'transaction-fee',
  TAX_FEE: 'tax-fee',
  DELIVERY_FEE: 'delivery-fee',
};

export const FeatureToggle = {
  FEATURE_ADMIN: 'FEATURE_ADMIN',
};

export const AppConstants = {
  ADMIN_HOSTNAME: 'ADMIN_HOSTNAME',
};
