import { ColorType, UIColors, OrganizationStaffRoleType, Status, StaffEventRole, Routes } from 'constants/index';
import { getPath } from './routes';

export * from './localStorage';
export * from './routes';
export * from './common/formatNumber';
export * from './timeHelper';
export * from './text';
export * from './formValidation';
export * from './categoryHelper';
export * from './common/helpers/priceHelper';
export * from './currencyHelper';
export * from './stringHelper';
export * from './eventHelper';
export * from './userHelper';
export * from './imageUtils';
export * from './appHelper';
export * from './common';

export const emptyFunction = () => {};

const adminConstantValues = [Status.APPROVED, OrganizationStaffRoleType.ADMIN, StaffEventRole.ADMIN];
const memberConstantValues = [Status.PENDING, OrganizationStaffRoleType.MEMBER, StaffEventRole.MEMBER];

export const removeEmptyFieldsInObj = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      delete obj[key];
    }
  });
  return obj;
};

export const getConstantValueByKeyOrValue = (Constant, keyOrValue) =>
  Object.entries(Constant)
    ?.map(([key, value]) => ({ key, value }))
    ?.find((item) => item.key === keyOrValue || item.value === keyOrValue) || { key: '', value: '' };

export const getLabelColor = (key) =>
  adminConstantValues.includes(key) ? 'success' : memberConstantValues.includes(key) ? 'warning' : 'default';

export const colorMapper = (type) => {
  const colors = Object.keys(UIColors);
  const getColor = colors.find((key) => ColorType[key].list.includes(type));

  return ColorType?.[getColor]?.color || 'default';
};

export const getRandomColor = () => {
  const colors = Object.keys(UIColors);

  return shuffle(colors)[0];
};

export const equateTheColorArrFromOtherArray = (array) => {
  const colors = Object.keys(UIColors);
  return array.map((_, i) => colors[i % colors.length]);
};

export function shuffle(array) {
  let currentIndex = array.length;
  let randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

export const whiteListedRoutes = [
  getPath(Routes.VERIFICATION),
  getPath(Routes.PRIVACY_POLICY),
  getPath(Routes.TERMS_OF_SERVICE),
];

export const isAdminHelper = (roles = []) =>
  (roles || []).some(
    (role) => role?.role === OrganizationStaffRoleType.ADMIN || role?.role === OrganizationStaffRoleType.CREATOR
  );

export const hasCreatorRole = (roles = []) =>
  (roles || []).some((role) => role?.role === OrganizationStaffRoleType.CREATOR);

export const filterByRoleHelper = (roles = [], type = OrganizationStaffRoleType.ADMIN) =>
  (roles || []).some((role) => role?.role === type);

export const filterWithObjectFieldInObject = (objectToPopulate = {}, objectToCheck = {}) => {
  const newObj = Object.entries(objectToPopulate)?.reduce((acc, [key, value]) => {
    if (Object.prototype.hasOwnProperty.call(objectToCheck, key)) {
      return {
        ...acc,
        [key]: value,
      };
    }
    return acc;
  }, []);

  return newObj;
};

/**
 * Empty Items
 */
export const emptyObject = Object.freeze({});

/**
 * Get object without particular keys
 */
export const omitProps = (object, propNames) => {
  return Object.keys(object).reduce((acc, key) => {
    if (propNames.includes(key)) return acc;
    acc[key] = object[key];
    return acc;
  }, {});
};

/**
 * Get object without all keys except particular list
 */
export const pickProps = (object, propNames) => {
  return propNames.reduce((acc, key) => {
    acc[key] = object[key];
    return acc;
  }, {});
};

export const removeDuplicateObject = (array = [], indicator = '') => {
  if (!indicator) {
    return array;
  }

  return array?.filter((value, index, self) => index === self.findIndex((t) => t?.[indicator] === value?.[indicator]));
};
