import { useCallback } from 'react';
import { capitalCase } from 'change-case';
import { Container, Tab, Box, Tabs } from '@mui/material';
import { useHistory } from 'react-router-dom';
import useTabs from 'hooks/useTabs';
import useSettings from 'hooks/useSettings';
import Page from 'components/Page';
import Iconify from 'components/Iconify';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import EventPrimary from 'sections/organization/event/setup/general/EventPrimary';
import { Routes } from 'constants/index';
import EventContactInformation from 'sections/organization/event/setup/general/EventContactInformation';
import PayoutOptionForm from 'sections/organization/event/setup/general/PayoutOptionForm';

export const TabNames = {
  PRIMARY: 'Primary',
  PAYOUT: 'PayoutOption',
  CONTACT: 'ContactInformation',
};

export default function EventGeneral() {
  const { themeStretch } = useSettings();
  const history = useHistory();
  const hashValue = window.location.hash.substring(1);

  const ACCOUNT_TABS = [
    {
      value: TabNames.PRIMARY,
      icon: <Iconify icon={'icon-park-outline:setting-web'} width={20} height={20} />,
      component: <EventPrimary />,
      path: Routes.EVENT_SETUP_GENERAL,
    },
    {
      value: TabNames.PAYOUT,
      icon: <Iconify icon={'mingcute:card-pay-line'} width={20} height={20} />,
      component: <PayoutOptionForm />,
      path: Routes.EVENT_SETUP_PAYOUT,
    },
    {
      value: TabNames.CONTACT,
      icon: <Iconify icon={'teenyicons:contact-outline'} width={20} height={20} />,
      component: <EventContactInformation />,
      path: Routes.EVENT_SETUP_CONTACT,
    },
  ];

  const { currentTab, onChangeTab } = useTabs(TabNames.PRIMARY, { tabs: ACCOUNT_TABS });

  const handleOnChangeOnTab = useCallback(
    (event, value) => {
      onChangeTab(event, value);
    },
    [history]
  );

  if (hashValue && hashValue !== currentTab) {
    onChangeTab(null, hashValue, hashValue);
  }

  return (
    <Page title="Event: General Settings">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading="Event Account" links={[{ name: 'Event' }, { name: 'Setup - General' }]} />

        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={handleOnChangeOnTab}
        >
          {ACCOUNT_TABS.map((tab) => {
            return (
              <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
            );
          })}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          if (isMatched) {
            return <Box key={tab.value}>{tab.component}</Box>;
          }

          return null;
        })}
      </Container>
    </Page>
  );
}
