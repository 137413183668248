import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { events, user, alerts, registration, races, app, organization, event } from './modules/index';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    user,
    organization,
    event,
    events,
    races,
    alerts,
    registration,
  });

export default rootReducer;
