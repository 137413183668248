import { createSelector } from 'reselect';

export const eventsSelector = createSelector(
  (state) => state.events,
  (events) => events
);

export const eventsDataSelector = createSelector(eventsSelector, (events) => events.eventsListData);

export const eventDataSelector = createSelector(eventsSelector, (events) => events.event);

export const metricUsedIsKmSelector = createSelector(eventDataSelector, (event) => event.metricUsedIsKm);

export const eventOrganizationProfileSelector = createSelector(
  eventDataSelector,
  (event) => event?.organization?.organizationProfilePhotoURLs?.items?.[0] || {}
);

export const eventCategoriesSelector = createSelector(eventDataSelector, (event) => event?.categories?.items || []);

export const eventSlotsAvailabilityIsSameForAllCategoriesSelector = createSelector(
  eventDataSelector,
  (event) => event?.slotsAvailabilityIsSameForAllCategories || false
);

export const eventFetchedSuccessSelector = createSelector(eventsSelector, (events) => events.eventFetchedSuccess);

export const getEventCategorySelector = (id) =>
  createSelector(eventCategoriesSelector, (categories) => categories.find((category) => category?.id === id) || null);

export const eventsFetchingSelector = createSelector(eventsSelector, (events) => events.eventsFetching);

export const eventFetchingSelector = createSelector(eventsSelector, (events) => events.eventFetching);

export const shouldDisplaySearchListSelector = createSelector(
  eventsSelector,
  (events) => events.shouldDisplaySearchList
);

export const eventSearchListDataSelector = createSelector(eventsSelector, (events) => events.eventSearchListData);

export const isWaiverFormSameForAllSelector = createSelector(
  eventDataSelector,
  (event) => event?.isWaiverFormSameForAll
);
