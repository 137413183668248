/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://hco6hjffrrfnjnibe2z7g2dayq.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-vzucc573wndevcgl3ygpfigdvi",
    "aws_cloud_logic_custom": [
        {
            "name": "paymentsApi",
            "endpoint": "https://bi7qcle90i.execute-api.ap-southeast-1.amazonaws.com/production",
            "region": "ap-southeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-1:4e35c350-1f38-4661-975d-766b88ad50d5",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_03LAuLZHT",
    "aws_user_pools_web_client_id": "5cq0o3kbq5lbqursv2ngoe9mvj",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "organization-bucket161409-production",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
