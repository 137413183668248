import { createAction } from 'redux-actions';
import fetch from 'utils/fetch';
import { listEvents, getEvent as getEventByIdQuery } from 'graphql/customQueries';
import { Logger } from 'aws-amplify';
import { EventErrorTypes } from 'modules/events/constants';
import { createAlert } from 'modules/alerts/actions';
import { toTitleCase } from 'utils';
import { eventsDataSelector } from './selector';
import {
  FETCH_EVENTS,
  EVENTS_FETCHED_SUCCESS,
  EVENTS_FETCHING,
  SEARCH_EVENTS,
  SHOULD_DISPLAY_SEARCH_LIST,
  CREATE_EVENT,
  GET_EVENT_BY_ID,
  EVENT_FETCHED_SUCCESS,
  EVENT_FETCHING,
} from './types';

const logger = new Logger('events/actions');

export const setEventsFetchedSuccess = createAction(EVENTS_FETCHED_SUCCESS, (value) => (dispatch) => value);
export const setEventFetchedSuccess = createAction(EVENT_FETCHED_SUCCESS, (value) => (dispatch) => value);
export const setEventsFetching = createAction(EVENTS_FETCHING, (value) => (dispatch) => value);
export const setEventFetching = createAction(EVENT_FETCHING, (value) => (dispatch) => value);
export const setShouldDisplaySearchList = createAction(SHOULD_DISPLAY_SEARCH_LIST, (value) => (dispatch) => value);

export const fetchEvents = createAction(FETCH_EVENTS, async (options) => async (dispatch, getState) => {
  dispatch(setEventsFetchedSuccess(false));
  dispatch(setEventsFetching(true));

  let filter = {};

  if (options?.isPublishedOnly) {
    filter = { ...filter, published: { eq: true } };
  }

  const hasFilter = Object.keys(filter).length > 0;

  try {
    const fetchOptions = { query: listEvents, ...(hasFilter && { variables: { filter } }) };
    const events = await fetch.graphql(fetchOptions);
    dispatch(setEventsFetchedSuccess(true));
    return events.data.listEvents.items ?? [];
  } catch (error) {
    logger.info('Events Fetching Error: ', error);
    dispatch(setEventsFetchedSuccess(false));
    dispatch(createAlert(EventErrorTypes.fetchingEventsError));
  }
  dispatch(setEventsFetching(false));
});

export const searchEvents = createAction(
  SEARCH_EVENTS,
  async (eventTitleSearch, options) => async (dispatch, getState) => {
    const state = getState();
    const eventsListData = eventsDataSelector(state);
    dispatch(setEventsFetchedSuccess(false));
    dispatch(setEventsFetching(true));

    if (!eventTitleSearch) {
      return null;
    }

    let filter = { name: { contains: eventTitleSearch?.toLowerCase() || '' } };

    if (options?.isPublishedOnly) {
      filter = { ...filter, published: { eq: true } };
    }

    try {
      const events = await fetch.graphql({
        query: listEvents,
        variables: { filter },
      });
      const result = events?.data?.listEvents?.items ?? [];
      if (!result.length) {
        dispatch(createAlert('CustomAlert', `We can't find any search for "${eventTitleSearch}"`));
        return eventsListData;
      }
      return result;
    } catch (error) {
      console.log('ERRORS', error);
      logger.info('Events Fetching Error: ', error);
      dispatch(setEventsFetchedSuccess(false));
      dispatch(createAlert(EventErrorTypes.searchingEventsError));
      dispatch(setEventsFetching(false));
    }
  }
);

export const createEvent = createAction(CREATE_EVENT, async () => async (dispatch, getState) => {
  dispatch(setEventsFetchedSuccess(false));
  dispatch(setEventsFetching(true));

  try {
    const events = await fetch.graphql({ query: listEvents });
    dispatch(setEventsFetchedSuccess(true));
    return events.data.listEvents.items ?? [];
  } catch (error) {
    logger.info('Events Fetching Error: ', error);
    dispatch(setEventsFetchedSuccess(false));
    dispatch(createAlert(EventErrorTypes.fetchingEventsError));
  }
  dispatch(setEventsFetching(false));
});

export const getEventById = createAction(GET_EVENT_BY_ID, async (id, options) => async (dispatch, getState) => {
  dispatch(setEventFetchedSuccess(false));
  dispatch(setEventFetching(true));

  try {
    const event = await fetch.graphql({ query: getEventByIdQuery, variables: { id } });
    dispatch(setEventFetchedSuccess(true));
    const eventData = event?.data?.getEvent ?? null;
    if (options?.isPublishedOnly && !eventData?.published) {
      return {
        data: null,
        error: 'EventNotPublished',
      };
    }

    return {
      data: eventData,
    };
  } catch (error) {
    logger.info('Event Fetching Error: ', error);
    dispatch(setEventFetchedSuccess(false));
  }
  dispatch(setEventFetching(false));
  return {
    data: null,
  };
});
