import { useState, useCallback } from 'react';
import { useParams, generatePath, useHistory } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { Slide, Button, IconButton, ClickAwayListener, Stack, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getPath } from 'utils';
import { Routes, ProfileView } from 'constants';
import { useDispatch, useSelector } from 'react-redux';
import { resetSelectedOrganization } from 'modules/organization/actions';
import { publishEvent, resetSelectedEvent } from 'modules/event/actions';
import { RedirectModal } from 'sections/general/RedirectModal';
import { organizationNameSelector, selectedEventIdSelector } from 'modules/organization/selector';
import { logoutUser } from 'modules/user/actions';
import { isAdminPortalSelector, profileSelector } from 'modules/app/selector';
import PublishEventModal from 'sections/organization/event/PublishEventModal';
import usePublishEventHook from 'hooks/usePublishEventHook';
import Iconify from 'components/Iconify';
import { fetchedEventIdSelector, isEventPublishedSelector, isUserAdminSelector } from 'modules/event/selector';
import { createAlert } from 'modules/alerts/actions';
import GeneralModal from 'components/GeneralModal';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const AdminNavigationBannerStyled = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  justifyContent: 'flex-end',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function AdminNavigationBanner() {
  const dispatch = useDispatch();
  const { organizationId } = useParams();
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isConfirmUnpublishEvent, setConfirmUnpublishEvent] = useState(false);
  const [isPublishErrorOpen, setPublishErrorOpen] = useState(false);
  const [isPublishOpen, setPublishOpen] = useState(false);
  const [openConfirmSwitchModal, setOpenConfirmModal] = useState(false);

  const selectedEventId = useSelector(selectedEventIdSelector);
  const organizationName = useSelector(organizationNameSelector);
  const profile = useSelector(profileSelector);
  const isAdminPortal = useSelector(isAdminPortalSelector);
  const fetchedEventId = useSelector(fetchedEventIdSelector);
  const isEventPublished = useSelector(isEventPublishedSelector);
  const isUserEventAdmin = useSelector(isUserAdminSelector);

  const lackingItems = usePublishEventHook();

  const handleOnClickPublish = useCallback(() => {
    if (isEventPublished) {
      return setConfirmUnpublishEvent((prev) => !prev);
    }
    if (lackingItems?.length) {
      setPublishErrorOpen((prev) => !prev);
    } else {
      setPublishOpen((prev) => !prev);
    }
  }, [lackingItems, isEventPublished]);

  const handlePublishEvent = useCallback(async () => {
    setPublishOpen(false);
    setConfirmUnpublishEvent(false);
    setIsPublishing(true);
    const response = await dispatch(publishEvent(!isEventPublished));
    if (response.payload.success) {
      dispatch(createAlert('CUSTOM_SUCCESS', 'Updated successfully'));
    } else {
      dispatch(createAlert('CUSTOM_ERROR', 'Unsuccessful updating, please try again later or contact support'));
    }
    setIsPublishing(false);
  }, [dispatch, isEventPublished]);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSwitchToUser = () => {
    setOpenConfirmModal((prev) => !prev);
  };

  const handleBackToOrganizationsModalConfirm = useCallback(() => {
    dispatch(resetSelectedOrganization());
    history.push(getPath(Routes.ORGANIZATIONS_DASHBOARD));
  }, [dispatch]);

  const handleBackToOrganizationModalConfirm = useCallback(() => {
    dispatch(resetSelectedEvent());
    history.push(generatePath(getPath(Routes.ORGANIZATION_DASHBOARD), { organizationId }));
  }, [dispatch, history, organizationId]);

  const handleStopImpersonating = useCallback(() => {
    dispatch(logoutUser(false));
  }, [dispatch]);

  const shouldDisplayPublishButton =
    profile === ProfileView.organization && selectedEventId && fetchedEventId && isUserEventAdmin;

  return (
    <>
      {openConfirmSwitchModal && <RedirectModal setOpen={setOpenConfirmModal} isOpen={openConfirmSwitchModal} />}

      {isPublishOpen && (
        <GeneralModal
          open={isPublishOpen}
          id={`publish-modal-display-id`}
          title="Publish Event Confirmation"
          message="Once this event is published this will be available and ready to accept registrants"
          buttonTwoText="Confirm"
          buttonTwoClick={handlePublishEvent}
          buttonOneText="Cancel"
          buttonOneClick={() => setPublishOpen((prev) => !prev)}
        />
      )}

      {isConfirmUnpublishEvent && (
        <GeneralModal
          open={isConfirmUnpublishEvent}
          id={`unpublish-modal-display-id`}
          title="Unpublish event confirmation"
          message="Once this event is unpublished this will be unavailable and cannot accept registrants anymore."
          buttonTwoText="Confirm"
          buttonTwoClick={handlePublishEvent}
          buttonOneText="Cancel"
          buttonOneClick={() => setConfirmUnpublishEvent((prev) => !prev)}
        />
      )}

      {isPublishErrorOpen && <PublishEventModal setOpen={handleOnClickPublish} open={isPublishErrorOpen} />}

      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <Stack spacing={2} direction="row">
            {!isOpen && (
              <Tooltip title="Open Admin Navigation">
                <IconButton onClick={handleOpen}>
                  <Iconify icon="icon-park:back" width={30} height={30} />
                </IconButton>
              </Tooltip>
            )}
            {shouldDisplayPublishButton && (
              <LoadingButton
                variant={isEventPublished ? 'outlined' : 'contained'}
                color={isEventPublished ? 'success' : 'error'}
                onClick={handleOnClickPublish}
                loading={isPublishing}
              >
                {isEventPublished ? 'Unpublish event' : 'Publish event'}
              </LoadingButton>
            )}
          </Stack>

          <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
            <AdminNavigationBannerStyled>
              <Stack spacing={2} direction="row">
                {profile === ProfileView.organization && (
                  <>
                    {organizationId && (
                      <Button variant="contained" onClick={handleBackToOrganizationsModalConfirm}>
                        {`Go to your list of organizations`}
                      </Button>
                    )}
                    {selectedEventId && (
                      <Button variant="contained" onClick={handleBackToOrganizationModalConfirm}>
                        {`Go to ${organizationName}`}
                      </Button>
                    )}
                    <Button variant="contained" onClick={handleSwitchToUser}>
                      {`Switch to user`}
                    </Button>
                  </>
                )}
                {isAdminPortal && (
                  <Button variant="contained" color="error" onClick={handleStopImpersonating}>
                    Stop impersonating
                  </Button>
                )}
              </Stack>
            </AdminNavigationBannerStyled>
          </Slide>
        </div>
      </ClickAwayListener>
    </>
  );
}
