import pathToRegexp, { compile } from 'path-to-regexp';
import qs from 'qs';
import { RoutesValue, Routes } from '../constants';

export const getPath = (route, { withoutParams = false } = {}) => {
  const path = RoutesValue[route]?.path || RoutesValue[Routes.EVENTS].path;
  return withoutParams ? path.slice(0, path.indexOf('/:')) : path;
};

export const getRouteValue = (route) => RoutesValue[route];

export const getRouteTitle = (route) => RoutesValue[route].title;

export const generateCategoryPath = (route) => RoutesValue[route].title;

export const isOneOfRoutes = (generatePath, adminRouts, location, params = {}) =>
  Object.entries(adminRouts)?.some(
    ([_, value]) =>
      generatePath(value.path, {
        ...params,
      }) === location.pathname
  );

export const constructPath = (path, params, generatePath) => generatePath(getPath(path), params);

const compiledCache = {};

export const generatePathWithQueryParams = (rawPath, params) => {
  let toPath;
  if (compiledCache[rawPath]) {
    toPath = compiledCache[rawPath];
  } else {
    toPath = compile(rawPath);
    compiledCache[rawPath] = toPath;
  }

  const queryParams = { ...params };
  const path = toPath(params, {
    encode: (value, token) => {
      delete queryParams[token.name];
      return encodeURI(value);
    },
  });

  const queryString = qs.stringify(queryParams);
  if (queryString) {
    return `${path}?${queryString}`;
  }
  return path;
};
