import { handleActions } from 'redux-actions';
import {
  GET_USER_DATA,
  USER_ERROR,
  LOADING_USER,
  LOGOUT_USER,
  NO_USER_IS_LOADED,

  // organizer Types
  USER_ORGANIZED_EVENTS,
  USER_ORGANIZATIONS,
  ADD_NEW_ORGANIZATION_TO_LIST,
  ADD_TO_USER_ORGANIZATION_LIST,
  REMOVE_JUNCTION_ORGANIZATION,
} from './types';

export const initialState = {
  data: {
    id: '',
    userId: '',
    email: '',
    isEmailVerified: false,
    phoneNumber: '',
    isPhoneNumberVerified: '',
    authTime: '',
    firstName: '',
    lastName: '',
    gender: '',
    birthDate: '',
    about: '',
    address: {
      primaryAddress: {
        city: '',
        cityCode: '',
        state: '',
        stateCode: '',
        country: 'Philippines',
        addressLine1: '',
        barangay: '',
        region: '',
        regionCode: '',
        zipCode: '',
      },
      billingShippingAddress: {
        city: '',
        state: '',
        country: '',
        addressLine1: '',
        barangay: '',
        region: '',
        zipCode: '',
      },
    },
  },
  subscriptions: [],
  subscription: {
    organizations: 0,
  },
  organizedEvents: [],
  organizations: [],
  isAuth: false,
  isUserInitialized: false,
  errors: [],
  isLoading: false,
  isSuccess: false,
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [GET_USER_DATA]: {
    next: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.user,
        },
        ...(action.payload.subscription && { subscription: action.payload.subscription }),
        isUserInitialized: true,
        isAuth: action.payload.shouldAuthenticate,
        isLoading: false,
        isSuccess: true,
      };
    },
  },
  [USER_ERROR]: {
    next: (state, action) => ({
      ...state,
      errors: [...state.errors, action.payload.error],
      isSuccess: false,
    }),
  },
  [LOADING_USER]: {
    next: (state, action) => ({
      ...state,
      isLoading: action.payload || false,
    }),
  },
  [LOGOUT_USER]: {
    next: (state, action) => ({
      ...initialState,
      isUserInitialized: true,
      isAuth: false,
      isLoading: false,
      isSuccess: false,
    }),
  },
  [NO_USER_IS_LOADED]: {
    next: (state, action) => ({
      ...state,
      isUserInitialized: true,
      isLoading: false,
      isSuccess: false,
    }),
  },
  // ORGANIZERS ACTIONS
  [USER_ORGANIZED_EVENTS]: {
    next: (state, action) => ({
      ...state,
      organizedEvents: [...action.payload],
    }),
  },
  [USER_ORGANIZATIONS]: {
    next: (state, action) => ({
      ...state,
      organizations: [...action.payload],
    }),
  },
  [ADD_NEW_ORGANIZATION_TO_LIST]: {
    next: (state, action) => ({
      ...state,
      organizations: [action.payload, ...state.organizations],
    }),
  },
  [ADD_TO_USER_ORGANIZATION_LIST]: {
    next: (state, action) => ({
      ...state,
      organizations: [action.payload, ...state.organizations],
    }),
  },
  [REMOVE_JUNCTION_ORGANIZATION]: {
    next: (state, action) => ({
      ...state,
      organizations: state.organizations.filter((organization) => organization.id !== action.payload),
    }),
  },
};

export default handleActions(ACTION_HANDLERS, initialState);
