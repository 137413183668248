import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { checkAlertType } from 'utils/toastValues';
// components
import ScrollToTop from 'components/ScrollToTop';
import { ProgressBarStyle } from 'components/ProgressBar';
import { BaseOptionChartStyle } from 'components/chart/BaseOptionChart';
import LoadingScreen from 'components/LoadingScreen';
import { removeSelectedOrganizationId } from 'modules/organization/actions';
import { Routes, ProfileView } from 'constants/index';
import { getPath } from 'utils';
import { Router } from './routes';

export const App = (props) => {
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(search);
  const history = useHistory();
  const {
    alerts,
    initializeApp,
    removeAllAlerts,
    isAppFullyInitialized,
    profile,
    selectedOrganizationId,
    selectedEventId,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (alerts && alerts.length > 0) {
      alerts.forEach((alert) => {
        const shouldPersist = Boolean(alert?.message?.length > 20);
        enqueueSnackbar(`${alert?.message ?? ''}`, {
          variant: checkAlertType(alert.type) || 'error',
          persist: shouldPersist,
          key: alert.id,
        });
      });
      removeAllAlerts();
    }
  }, [alerts]);

  useEffect(() => {
    initializeApp(pathname);
  }, []);

  useEffect(() => {
    if (isAppFullyInitialized && profile === ProfileView.organization && pathname) {
      if (selectedOrganizationId && selectedEventId) {
        const redirectOrganizationEventPath = generatePath(getPath(Routes.EVENT_PORTAL), {
          organizationId: selectedOrganizationId,
          selectedEventId,
        });

        // If Requested path is path of organizer
        if (pathname.includes(redirectOrganizationEventPath)) {
          history.push({
            pathname: pathname,
            search: queryParams.toString(),
          });
        } else {
          // default view
          history.push({
            pathname: generatePath(getPath(Routes.EVENT_DASHBOARD), {
              organizationId: selectedOrganizationId,
              selectedEventId,
            }),
            search: queryParams.toString(),
          });
        }
      } else if (selectedOrganizationId) {
        const redirectOrganizationPath = generatePath(getPath(Routes.ORGANIZATION_DASHBOARD), {
          organizationId: selectedOrganizationId,
        });
        if (!pathname.includes(redirectOrganizationPath)) {
          history.push({
            pathname: redirectOrganizationPath,
            search: queryParams.toString(),
          });
        }
      } else {
        dispatch(removeSelectedOrganizationId());
        history.push({
          pathname: getPath(Routes.ORGANIZATIONS_DASHBOARD),
          search: queryParams.toString(),
        });
      }
    }
  }, [isAppFullyInitialized, profile]);

  return (
    <>
      {isAppFullyInitialized ? (
        <>
          <ProgressBarStyle />
          <ScrollToTop />
          <BaseOptionChartStyle />
          <Router {...props} />
        </>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

App.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  isEventAdmin: PropTypes.bool.isRequired,
  alertsSelector: PropTypes.array,
  removeAlert: PropTypes.func,
  initializeApp: PropTypes.func.isRequired,
  isAppFullyInitialized: PropTypes.bool.isRequired,
};
