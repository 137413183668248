import { S3FileType, S3ImageSizes } from 'constants/index';

export const imageSizesKeys = Object.keys(S3ImageSizes);

export const formatKeyImageUrl = (key, { size = S3ImageSizes.original }) => {
  let newKey = key;
  if (size) {
    const urlStoredSize = imageSizesKeys.find((val) => key.includes(val));
    newKey = urlStoredSize && size !== urlStoredSize ? key.replace(urlStoredSize, `_${size}`) : key;
  }

  return newKey;
};

export const isExpiredImageUrl = (url, moment) => {
  const urlParams = url?.split('&');
  const createdDate = moment(urlParams?.find((val) => val.includes('X-Amz-Date'))?.split('=')?.[1] || 0);
  const expireSeconds = moment(
    parseInt(urlParams?.find((val) => val.includes('X-Amz-Expires'))?.split('=')?.[1] || 0, 10) || 0
  ); // to convert into milis
  const expirationMillis = moment(createdDate).add(expireSeconds, 'seconds');
  const isAfterNow = moment().isAfter(moment(expirationMillis));

  return isAfterNow;
};

export const getFileNameFromKeyValueWithS3Access = (key, size, isForce) =>
  getFileNameFromKeyValue(key?.split('.')?.[0], size, true);

export const getFileNameFromKeyValue = (key, size = '', forceSize = false) => {
  const fileArr = key?.split('/');

  const file = fileArr?.[fileArr?.length - 1];

  const urlStoredSize = imageSizesKeys.find((val) => key.includes(val));

  const sizeFormat = size || S3ImageSizes?.[urlStoredSize] || '';

  const fileContainsSize = imageSizesKeys?.some((val) => file.includes(val));

  const isForceQualifiedCheck = file && forceSize && size;

  if (isForceQualifiedCheck) {
    const fileWithRemoveSize = fileContainsSize ? file?.split('_')?.slice(0, -1)?.join('_') : file;
    const isOrginal = sizeFormat === S3ImageSizes.original;
    const saveFileFormat = isOrginal ? `${fileWithRemoveSize}_${sizeFormat}` : `${fileWithRemoveSize}${sizeFormat}`;
    return saveFileFormat;
  }

  return file
    ? `${file?.split('.')?.[0]}${
        sizeFormat && !fileContainsSize
          ? sizeFormat !== S3ImageSizes.original
            ? `_${sizeFormat}`
            : `${sizeFormat}`
          : ''
      }`
    : null;
};

export const fileNameFormatter = (type, size = S3ImageSizes.original, options) => {
  let fileName = '';

  fileName = `${type}`;

  if (size === S3ImageSizes._300x300) {
    fileName = `${fileName}/_300x300`;
  } else if (size === S3ImageSizes._500x500) {
    fileName = `${fileName}/_500x500`;
  } else if (size === S3ImageSizes._1000x1000) {
    fileName = `${fileName}/_1000x1000`;
  } else {
    fileName = `${fileName}/original`;
  }

  return `${fileName}/${options?.S3FileName}`;
};

const TypeOfAction = {
  put: 'put',
  get: 'get',
};

const StorageType = {
  USERS: 'users',
  ORGANIZATIONS: 'organizations',
};

const OrganzationStorageType = {
  EVENTS: 'events',
};

const EventsStorageType = {
  categories: 'categories',
};

const CategoriesStorageType = {
  ROUTES: 'routes',
  KITS: 'kits',
};

const CommonType = {
  PROFILE: 'profile',
};

const StorageTypeCategory = {
  ...CategoriesStorageType,
  ...CommonType,
};

export const keyConstructor = (type, fileName_, typeOfAction = TypeOfAction.put, size = S3ImageSizes.original) => {
  let constructedKey = '';
  constructedKey =
    typeOfAction === TypeOfAction.put
      ? `${constructedKey}/${S3ImageSizes.original}`
      : `public/${constructedKey}/${S3ImageSizes.original}/${type}`;

  // always save the image file as jpg
  return `${constructedKey}/${fileName_.split('/').pop().split('.').slice(0, -1).join('')}.jpg`;
};

export const keyS3Construction = (options, fileName_, size = S3ImageSizes.original) => {
  const { categoryId, selectedEventId: eventId, organizationId, userId, receiptId, type } = options;

  const keyLocation = (() => {
    let constructedKey = '';

    if (organizationId) {
      constructedKey = `organizations/${organizationId}`;

      if (eventId) {
        constructedKey = `${constructedKey}/events/${eventId}`;

        if (categoryId) {
          constructedKey = `${constructedKey}/categories/${categoryId}`;
        }
      }
    } else if (userId) {
      constructedKey = `users/${userId}`;

      if (receiptId) {
        constructedKey = `${constructedKey}/receipts/${receiptId}`;
      }
    }

    constructedKey = `${constructedKey}/${type}`;

    return constructedKey;
  })();

  // always save the image file as jpg
  return `${keyLocation}/${fileName_.split('/').pop().split('.').slice(0, -1).join('')}_${size}.jpg`;
};

const ICON_SIZE = 32;

export const getIcon = (icon, color) =>
  `url(https://api.iconify.design/carbon/${icon}.svg?color=%23${color}&width=${ICON_SIZE}&height=${ICON_SIZE})`;
