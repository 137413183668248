import uuidv4 from 'utils/uuidv4';
import { FieldInitialValues, PriceType } from 'constants/index';
import { camelCase } from 'change-case';
import { formatCategoryTypeAndValue } from 'utils/eventHelper';
import { getNumericChars, hasNumber } from 'utils/stringHelper';
import { FieldProperties, fieldTypes } from 'components/hook-form';
import moment from 'moment-timezone';

export const formFieldsRegrouping = (originalFormFields, newFormFields = null, numberOfRequiredForm) => {
  if (numberOfRequiredForm === 1 || !newFormFields) {
    return [originalFormFields];
  }
  const originalFormFieldsLength = Object.keys(originalFormFields).length;

  const newFormFieldsLength = Object.keys(newFormFields).length;

  if (newFormFieldsLength % originalFormFieldsLength) {
    console.log('object between newForm original form is not equal');
    return [originalFormFields];
  }

  return [...Array(numberOfRequiredForm)].reduce(
    (acc, _, index) => [
      ...acc,
      newFormFields.slice(index * originalFormFieldsLength, (index + 1) * originalFormFieldsLength),
    ],
    []
  );
};

const toCent = (amount) => {
  const str = amount.replace(',', '.');
  return str.length < 3 || str[str.length - 3] === '.'
    ? Number(str.replace('.', ''))
    : Number(str.replace('.', '')) * 100;
};

export const categoryFeePriceCreator = (price, currency = 'PHP', registrationEnd, registrationStart) => {
  const parseFloatPrice = Number.parseFloat(price).toFixed(2);

  const amountInCent = toCent(parseFloatPrice);

  return {
    type: PriceType.REGULAR_PRICE,
    currency: currency || 'PHP',
    amountInCent,
    enabled: true,
    priceValidFrom: registrationStart || '',
    priceValidTo: registrationEnd || '',
  };
};

const formatToCent = (price) => {
  const parseFloatPrice = Number.parseFloat(price).toFixed(2);
  return toCent(parseFloatPrice);
};

export const formatFees = (prices, eventCreatedDate, eventEndDate) => {
  return prices?.map((price) => {
    return {
      type: price?.type || PriceType.REGULAR_PRICE,
      currency: price?.currency || 'PHP',
      amountInCent: formatToCent(price?.amountInCent || '0'),
      enabled: Boolean(price?.enabled),
      priceValidFrom: moment(price?.priceValidFrom || eventCreatedDate).toISOString(),
      priceValidTo: moment(price?.priceValidTo || eventEndDate).toISOString(),
    };
  });
};

export const sortFormFields = (formFields) => formFields?.sort((a, b) => a?.orderNumber - b?.orderNumber);

export const sortForms = (forms) => forms?.sort((a, b) => a?.formOrderNumber - b?.formOrderNumber);

export const sortFormsByLatestUpdate = (forms) =>
  forms?.sort((a, b) => (moment(a?.updatedAt).isBefore(moment(b?.updatedAt)) ? 1 : -1));

export const sortFormFieldsByIndex = (formFields) =>
  formFields?.reduce(
    (acc, item, index) => [
      ...acc,
      {
        ...item,
        orderNumber: index,
      },
    ],
    []
  );

export const reconstructedFieldValidations = (field, isExistingId) => {
  const isTextField = field?.fieldType === fieldTypes.TextBox;
  const newFieldId = isExistingId ? uuidv4() : '';

  if (!isTextField) {
    return field;
  }

  const type = field?.type;
  const isEmailType = type === FieldProperties.TYPE_EMAIL;
  let validations = field?.validations;
  const isRequired = validations?.some((item) => item?.type === FieldProperties.REQUIRED);

  // Not Required
  if (!isRequired) {
    // Remove Min Value but retain max
    validations = validations.filter((item) => item?.type !== FieldProperties.MIN_VALUE);
  }

  // Email Type remove Min and Max
  if (isEmailType) {
    validations = validations.filter(
      (item) => item?.type !== FieldProperties.MIN_VALUE && item?.type !== FieldProperties.MAX_VALUE
    );
  }

  return {
    ...field,
    ...(newFieldId && { newFieldId }),
    validations,
  };
};

export const removeNewFieldId = ({ newFieldId = undefined, ...rest }) => ({ ...rest });

export const formFieldInitiator = (fieldType = fieldTypes.TextBox, index = 0) => {
  return FieldInitialValues(index)[fieldType];
};

export const constructPrizeValue = (prizeValue = []) =>
  prizeValue
    ?.map((item) => ` ${item?.prizeAmountType || ''} ${item?.prizeAmountValue ? ` - ${item?.prizeAmountValue}` : ''}`)
    .join(`, `);

export const sortedCategoryFormsByLastUpdate = (category) =>
  category?.waiverForm?.items.sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt))) || {};

// CANNOT BE USED IN BACKEND
// should be populated with distancesSelector
export const isDistanceAlreadyExistsChecker = ({ formValues, distances, metricUsedIsKm, distanceSelectValue }) => {
  // format first distances value
  distances = distances?.reduce((acc, item) => {
    acc.push({
      ...item,
      categoryTypeAndValue: camelCase(item?.categoryTypeAndValue)?.toLowerCase(),
    });
    return acc;
  }, []);
  const { distance, categoryValue } = getDistanceTypeAndValueFromForm({
    distanceSelect: distanceSelectValue,
    formCategoryValue: formValues?.categoryValue,
    categoryType: formValues?.categoryType,
    distanceValue: formValues?.distance,
  });

  const formDistance = {
    distance,
    categoryValue,
    categoryType: formValues?.categoryType,
  };

  return isDistanceAlreadyExists({ metricUsedIsKm, distance: formDistance, distances });
};

// should be populated with distancesSelector
export const isDistanceAlreadyExists = ({ distances, distance, metricUsedIsKm }) => {
  const distanceFromForm = camelCase(formatCategoryTypeAndValue(metricUsedIsKm, distance)).toLowerCase();
  return distances?.map((dist) => dist?.categoryTypeAndValue)?.includes(distanceFromForm);
};

export const getDistanceTypeAndValueFromForm = ({ distanceSelect, formCategoryValue, distanceValue, categoryType }) => {
  let distance = '';
  let categoryValue = formCategoryValue;

  if (distanceSelect !== 'Other') {
    if (hasNumber(distanceSelect)) {
      distance = `${getNumericChars(distanceSelect)}`;
    } else {
      distance = `${distanceSelect}`;
    }
  } else {
    distance = `${getNumericChars(distanceValue)}`;
  }

  if (categoryType?.toLocaleLowerCase() === 'open') {
    categoryValue = '';
  }

  return {
    distance,
    categoryValue,
  };
};
