import { EVENT_STATUS } from 'constants/index';
import moment from 'moment-timezone';
import { CommonHelpers } from 'utils/common/index';

export const formatDistanceValue = CommonHelpers.EventHelpers.formatDistanceValue;

export const checkEventAvailability = (
  eventDate,
  regEndDate,
  categories,
  slotsAvailabilityIsSameForAllCategories,
  options
) => {
  // 1. Check event Availability for registration
  // 1.1 Date of event (PAST, INCOMING)
  // 1.2 Stocks Availability
  // 1.3 Prices availability - not applicable as of the moment

  const response = {
    isAvailable: false,
    status: EVENT_STATUS.CLOSE,
  };

  const registrationEndDate = getRegistrationEndDate(regEndDate, eventDate);

  // 1.1 Date availability
  const { value } = statusChecker(registrationEndDate, eventDate);
  if (value === EVENT_STATUS.CLOSE || value === EVENT_STATUS.DONE) {
    response.isAvailable = false;
    response.status = value;
    return response;
  }

  if (options?.categoryId) {
    const isCatAvailable =
      totalSlotsAndRegistrants(categories, slotsAvailabilityIsSameForAllCategories, options?.categoryId)
        ?.availableSlots > 0;
    response.isAvailable = isCatAvailable;
    response.status = isCatAvailable ? EVENT_STATUS.OPEN : EVENT_STATUS.NO_SLOTS_AVAILABLE;
    return response;
  }

  // 1.2 Stocks Availability
  const categoriesAvailability = categories?.map((category) =>
    isCategorySlotsAvailable(categories, category, slotsAvailabilityIsSameForAllCategories)
  );

  if (categories && categories?.length && !categoriesAvailability?.includes(true)) {
    response.isAvailable = false;
    response.status = EVENT_STATUS.NO_SLOTS_AVAILABLE;
    return response;
  }

  return {
    isAvailable: true,
    status: EVENT_STATUS.OPEN,
  };
};

export const getRegistrationEndDate = (registrationEndDate, dateOfEvent) => {
  return moment(registrationEndDate || dateOfEvent);
};

export const getRegistrationStartDate = (registrationStartDate, eventCreaedAt) => {
  return moment(registrationStartDate || eventCreaedAt);
};

export const statusChecker = (regEndDate, eventDate) => {
  const registrationEndDate = getRegistrationEndDate(regEndDate, eventDate);

  const dateOfEventDuration = moment.duration(moment(eventDate).diff(moment()));
  const dateOfEventDurationInHours = dateOfEventDuration.asHours();
  const regEndAndTodayDiff = moment.duration(registrationEndDate.diff(moment()));
  const regEndAndTodayDiffInHours = regEndAndTodayDiff.asHours();

  if (dateOfEventDurationInHours <= 0) return { value: EVENT_STATUS.DONE, color: 'info' };
  if (regEndAndTodayDiffInHours > 0) return { value: EVENT_STATUS.OPEN, color: 'success' };
  return { value: EVENT_STATUS.CLOSE, color: 'error' };
};

export const isCategorySlotsAvailable = (categories, category, slotsAvailabilityIsSameForAllCategories) => {
  const test =
    totalSlotsAndRegistrants(categories, slotsAvailabilityIsSameForAllCategories, category?.id)?.availableSlots > 0;
  return test;
};

export const slotsAvailabilityCalculator = (slots) =>
  Number.parseInt(slots?.totalSlotsAvailable || 0, 10) - Number.parseInt(slots?.totalRegistrants || 0, 10);

export const totalSlotsAvailableCalculator = (categories, slotsAvailabilityIsSameForAllCategories) => {
  if (slotsAvailabilityIsSameForAllCategories) {
    return totalSlotsAndRegistrants(categories, slotsAvailabilityIsSameForAllCategories, categories?.[0]?.id)
      ?.availableSlots; // Total slots is same for all categories
  }

  return categories
    ?.map(
      (category) =>
        totalSlotsAndRegistrants(categories, slotsAvailabilityIsSameForAllCategories, category?.id)?.availableSlots
    )
    ?.reduce((acc, item) => acc + item, 0);
};

export const getTheSmallestSlotsCount = (categories) =>
  Math.min(
    ...(categories?.length ? categories?.map((cat) => Number.parseInt(cat?.slots?.totalSlotsAvailable, 10)) : [])
  );

export const getCategoryTotalSlots = (categories, slotSameForAllCat, categoryId) => {
  if (slotSameForAllCat) {
    return getTheSmallestSlotsCount(categories);
  }

  if (categoryId && !slotSameForAllCat) {
    return categories?.find((cat) => cat?.id === categoryId)?.slots?.totalSlotsAvailable;
  }

  return categories?.[0]?.slots?.totalSlotsAvailable;
};

export const totalSlotsAndRegistrants = (categories, slotSameForAllCat, categoryId) => {
  const totalRegistrants = slotSameForAllCat
    ? getTotalRegistrantsForAllCategories(categories)
    : getTotalRegistrantsForCategory(categories, categoryId);
  const totalSlots = getCategoryTotalSlots(categories, slotSameForAllCat, categoryId);
  const availableSlots = totalSlots - totalRegistrants;

  return {
    totalRegistrants,
    totalSlots,
    availableSlots,
  };
};
export const getTotalRegistrantsForCategory = (categories, categoryId = '') => {
  const total = categories?.find((cat) => cat?.id === categoryId)?.slots?.totalRegistrants;
  return total;
};

export const getTotalRegistrantsForAllCategories = (categories = []) => {
  const total = categories?.reduce((acc, cat) => acc + Number.parseInt(cat?.slots?.totalRegistrants || 0, 10), 0);
  return total;
};

export const getOrganizerProfile = (organizers, Type) => {
  const type = Array.isArray(Type) ? Type : [Type];
  const filteredByType = organizers?.items?.filter((item) => item?.roles?.some((role) => type?.includes(role)));

  if (type?.length === 1) {
    return filteredByType?.[0]?.user || null;
  }

  return filteredByType;
};

export const getCategories = (categories, metric) => {
  return categories?.map((category) => formatDistanceValue(metric, category?.distance));
};

// export const formatDistanceValue = (metric = true, distance) => {
//   const isNumber = !Number.isNaN(parseFloat(distance));

//   return isNumber ? `${distance}${metric || metric === null ? 'km' : 'miles'}` : distance;
// };

export const formatCategoryValue = (metric, category) => {
  return `${formatDistanceValue(metric, category?.distance) || ''} - ${category?.categoryValue || 'Open'}`;
};

export const formatCategoryTypeAndValue = (metric, category) => {
  return `${formatDistanceValue(metric, category?.distance) || ''} - ${
    category?.categoryType?.toLowerCase() === 'open'
      ? category?.categoryType
      : `${category?.categoryType} - ${category?.categoryValue}`
  }`;
};

export const formatCategoryForNavigation = (metric, category) => {
  return {
    name: formatCategoryValue(metric, category),
    eventId: category?.eventId,
    categoryId: category?.id,
  };
};
