import { createSelector } from 'reselect';
import { FieldsToInclude, OrganizationStaffRoleType } from 'constants/index';
import { formatCategoryTypeAndValue, formatDistanceValue, hasCreatorRole } from 'utils/index';

export const rootSelector = createSelector(
  (state) => state.event,
  (event) => event
);

export const isInitializedSelector = createSelector(rootSelector, (event) => event.isInitialized);

export const eventDataSelector = createSelector(rootSelector, (event) => event.event);

export const eventDataIsFetchedSelector = createSelector(
  rootSelector,
  (event) => event.eventDataIsFetchedSuccess && !event.eventDataIsFetching
);

export const eventDataDataSelector = createSelector(eventDataSelector, (event) => event.data);

export const eventNameSelector = createSelector(eventDataDataSelector, (event) => event?.eventName);

export const fetchedEventIdSelector = createSelector(eventDataDataSelector, (data) => data?.id || '');

export const eventPayoutOptionDataSelector = createSelector(
  eventDataDataSelector,
  (data) => data?.payoutOption || null
);

export const eventContactInfoSelector = createSelector(eventDataDataSelector, (event) => event.contactInfo);

export const isMetricUsedIsKmSelector = createSelector(eventDataDataSelector, (event) => event?.metricUsedIsKm || true);

export const isUserAdminSelector = createSelector(eventDataSelector, (event) => event.isUserAdmin);
export const userEventRoles = createSelector(eventDataSelector, (event) => event.roles);

export const eventOdooDataDataSelector = createSelector(eventDataSelector, (event) => event.odooData);

export const eventDashboardSelector = createSelector(rootSelector, (event) => event.dashboard);

export const isEventDashboardDataFetchedSelector = createSelector(
  rootSelector,
  (event) => event.isEventDashboardDataFetched
);

export const isEventDashboardDataFetchingSelector = createSelector(
  rootSelector,
  (event) => event.isEventDashboardDataFetching
);

export const registrantsSelector = createSelector(rootSelector, (event) => event.registrants?.list);

export const registrantsTokenSelector = createSelector(rootSelector, (event) => event.registrants?.nextToken);

export const registrantsIsFetchingSelector = createSelector(
  rootSelector,
  (event) => event.isEventRegistrationsFetching
);

export const orderRegistrationDetailsSelector = createSelector(rootSelector, (event) => event.orderRegistrationDetails);

export const orderRegistrationDetailsFetchedSelector = createSelector(
  rootSelector,
  (event) => event.orderRegistrationDetailsFetched
);

export const eventStaffsSelector = createSelector(rootSelector, (event) => event?.staffs);

export const eventStaffSelector = createSelector(rootSelector, (event) => event?.staff);

export const eventStaffMemberListSelector = createSelector(eventStaffsSelector, (staffsList) =>
  staffsList?.filter((staff) => staff?.roles?.some((role) => role?.role === OrganizationStaffRoleType.MEMBER))
);

export const eventStaffAdminListSelector = createSelector(eventStaffsSelector, (staffsList) =>
  staffsList?.filter((staff) =>
    staff?.roles?.some(
      (role) => role?.role === OrganizationStaffRoleType.ADMIN || role?.role === OrganizationStaffRoleType.CREATOR
    )
  )
);

export const getFetchingEventStaffsState = createSelector(rootSelector, (event) => ({
  success: event?.isEventStaffsFetchSuccess,
  fetched: event?.isEventStaffsFetched,
}));

export const isEventStaffCreatorSelector = createSelector(eventStaffSelector, (staff) => hasCreatorRole(staff?.roles));

export const eventStaffIsFetchedSelector = createSelector(rootSelector, (event) => event?.isStaffFetched);

export const categoriesSelector = createSelector(rootSelector, (event) => event?.categories || []);

export const categoriesFormsSelector = createSelector(categoriesSelector, (categories) =>
  categories?.reduce((acc, category) => {
    const forms = category?.form?.items;

    return [...acc, ...forms];
  }, [])
);

export const categoriesFormsFieldsKeysSelector = createSelector(categoriesFormsSelector, (forms) => {
  const fieldIds = forms?.reduce((acc, form) => {
    const formFields = form?.formFields;

    formFields?.forEach((field) => {
      acc.push(field?.fieldId);
    });

    return acc;
  }, []);

  // remove redundant field
  const constantFields = Object.values(FieldsToInclude);
  const fields = new Set([...constantFields, ...fieldIds]);

  return fields;
});

export const distancesSelector = createSelector(
  [categoriesSelector, isMetricUsedIsKmSelector],
  (categories, metricUsedIsKm) => {
    const arrayOfDistance =
      categories?.map((item) => ({ distance: item?.distance, id: item?.id, category: item })) || [];

    const distances = arrayOfDistance
      ?.sort((a, b) => a?.distance - b?.distance)
      ?.map((dist) => ({
        distance: `${formatDistanceValue(metricUsedIsKm, dist?.distance)}`,
        id: dist?.id,
        categoryTypeAndValue: formatCategoryTypeAndValue(metricUsedIsKm, dist?.category),
      }));

    return distances;
  }
);

export const categorySelector = createSelector(rootSelector, (event) => event?.category);

export const categoryRegPrice = createSelector(categorySelector, (category) => category?.regPrice || {});

export const categoryFeesSelector = createSelector(categoryRegPrice, (price) => price?.prices || []);

export const eventDateSelector = createSelector(eventDataDataSelector, (event) => event?.dateOdEvent || '');

export const eventCreatedDateSelector = createSelector(eventDataDataSelector, (event) => event?.createdAt || '');

export const hasSameFormForAllCategoriesSelector = createSelector(eventDataDataSelector, (event) =>
  Boolean(event?.hasSameFormForAllCategories)
);

export const categoryFormSelector = createSelector(rootSelector, (event) => event?.categoryForms || null);

export const draftCategoryFormsSelector = createSelector(rootSelector, (event) => event?.draftCategoryForms);

export const hasSameRouteForAllCatSelector = createSelector(
  eventDataDataSelector,
  (event) => event?.hasSameRouteForAllCat
);

export const slotsAvailabilityIsSameForAllCategoriesSelector = createSelector(
  eventDataDataSelector,
  (event) => event?.slotsAvailabilityIsSameForAllCategories
);

export const isWaiverFormSameForAllSelector = createSelector(
  eventDataDataSelector,
  (event) => event?.isWaiverFormSameForAll
);

export const isEventPublishedSelector = createSelector(eventDataDataSelector, (event) => event?.published || false);
