// import { fieldTypes } from 'components/hook-form';

export const distances = ['3km', '5km', '10km', '15km', '20km', 'Half-Marathon', 'Marathon', 'Other'];

export const defaultFormId = 'no-id-form';

export const defaultCategoryForm = {
  id: `${defaultFormId}-1`,
  eventId: '',
  formOrderNumber: 1,
  categoryId: '',
  updatedAt: '2024-02-02T04:20:44.927Z', // Dummy date
  formFields: [
    {
      defaultValue: null,
      fieldId: 'firstName',
      fieldType: 'TextBox',
      label: 'First Name',
      options: [],
      orderNumber: 0,
      placeholder: 'Prince Joey',
      type: null,
      validationType: 'string',
      validations: [
        {
          params: '[3,"FirstName cannot be less than 3 characters"]',
          type: 'min',
        },
        {
          params: '[50,"FirstName cannot be more than 50 characters"]',
          type: 'max',
        },
        {
          params: '["FirstName is required"]',
          type: 'required',
        },
      ],
    },
    {
      defaultValue: null,
      fieldId: 'lastName',
      fieldType: 'TextBox',
      label: 'Last  Name',
      options: [],
      orderNumber: 1,
      placeholder: 'Lee',
      type: null,
      validationType: 'string',
      validations: [
        {
          params: '[3,"Last Name cannot be less than 3 characters"]',
          type: 'min',
        },
        {
          params: '[50,"Last Name cannot be more than 50 characters"]',
          type: 'max',
        },
        {
          params: '["Last Name is required"]',
          type: 'required',
        },
      ],
    },
    {
      defaultValue: null,
      fieldId: 'age',
      fieldType: 'TextBox',
      label: 'Age on the race day',
      options: [],
      orderNumber: 2,
      placeholder: '',
      type: 'number',
      validationType: 'number',
      validations: [
        {
          params: '[2,"Should be at least 2 years old"]',
          type: 'min',
        },
        {
          params: '["Age is required"]',
          type: 'required',
        },
      ],
    },
    {
      defaultValue: null,
      fieldId: 'gender',
      fieldType: 'Radio',
      label: 'Gender',
      options: [
        {
          label: 'Male',
          value: 'Male',
        },
        {
          label: 'Female',
          value: 'Female',
        },
      ],
      orderNumber: 3,
      placeholder: '',
      type: null,
      validationType: 'string',
      validations: [
        {
          params: '["Gender is required"]',
          type: 'required',
        },
      ],
    },
    {
      defaultValue: null,
      fieldId: 'email',
      fieldType: 'TextBox',
      label: 'Email',
      options: [],
      orderNumber: 4,
      placeholder: 'Prince Joey',
      type: null,
      validationType: 'email',
      validations: [
        {
          params: '["please enter a valid email"]',
          type: 'email',
        },
        {
          params: '["Email is required"]',
          type: 'required',
        },
      ],
    },
    {
      defaultValue: null,
      fieldId: 'shirtSize',
      fieldType: 'Select',
      label: 'T-Shirt Size',
      options: [
        {
          label: 'Small',
          value: 'Small',
        },
        {
          label: 'Medium',
          value: 'Medium',
        },
        {
          label: 'Large',
          value: 'Large',
        },
      ],
      orderNumber: 5,
      placeholder: '',
      type: null,
      validationType: '',
      validations: [
        {
          params: '["Shirt Size is required"]',
          type: 'required',
        },
      ],
    },
    {
      defaultValue: null,
      fieldId: 'hearEvent',
      fieldType: 'Checkbox',
      label: 'Where did you hear this event ? ',
      options: [
        {
          label: 'Social Media',
          value: 'SocialMedia',
        },
        {
          label: 'Poster',
          value: 'Poster',
        },
        {
          label: 'Email',
          value: 'Email',
        },
      ],
      orderNumber: 6,
      placeholder: '',
      type: null,
      validationType: '',
      validations: [
        {
          params: '["Question is required"]',
          type: 'required',
        },
      ],
    },
  ],
};

const defaultOptions = [
  {
    label: 'option 1',
    value: 'option 1',
  },
  {
    label: 'option 2',
    value: 'option 2',
  },
];

export const FieldInitialValues = (index) => ({
  TextBox: {
    defaultValue: null,
    fieldId: `fieldId-${index}`,
    fieldType: 'TextBox',
    label: `Label ${index}`,
    options: [],
    orderNumber: index,
    placeholder: '',
    type: null,
    validationType: 'string',
    validations: [],
  },
  Select: {
    defaultValue: null,
    fieldId: `fieldId-${index}`,
    fieldType: 'Select',
    label: `Label ${index}`,
    options: defaultOptions,
    orderNumber: index,
    placeholder: '',
    type: null,
    validationType: 'string',
    validations: [],
  },
  Radio: {
    defaultValue: null,
    fieldId: `fieldId-${index}`,
    fieldType: 'Radio',
    label: `Label ${index}`,
    options: defaultOptions,
    orderNumber: index,
    placeholder: '',
    type: null,
    validationType: 'string',
    validations: [],
  },
  Checkbox: {
    defaultValue: null,
    fieldId: `fieldId-${index}`,
    fieldType: 'Checkbox',
    label: `Label ${index}`,
    options: defaultOptions,
    orderNumber: index,
    placeholder: '',
    type: null,
    validationType: 'string',
    validations: [],
  },
});

export const defaultWaiver = `Running is a potentially hazardous activity. I should not enter and run unless I am medically able and properly trained. I agree to abide by any decision of a race official relative to my ability to safely complete the run.

I hereby certify that I am in good health and I have trained to run the distance of the race, which I am entering. I assume all risks associated with running in this event including, but not limited to: falls/stumbling, suffering heat stroke, heart attack, and other similar risks, contact with other participants, the effects of weather, including high heat and/or humidity, all such risks being known and appreciated by me. 

Having read this waiver and knowing these facts and in consideration of your accepting my entry into this marathon. I, for myself and anyone entitled to act on my behalf, waive and release, its officers, directors, agents, volunteers and employees, or other government bodies or locations in which events or segments of events are held, all sponsors their representatives, and successors, from all claims or liabilities of any kind arising out my participation in this event though that liability may arise out of negligence or carelessness on the part of the person named in this waiver. `;

export const FieldsToInclude = {
  registeredDate: 'Registered Date',
  bibNumber: 'bibNumber',
  distance: 'Distance',
  firstName: 'firstName',
  lastName: 'lastName',
  gender: 'gender',
  email: 'registrantEmail',
};
