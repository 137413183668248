import { TermsOfServiceMarkdown, PrivacyPolicyMarkdown } from './longTextConstants';

export const Attestations = {
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  TERMS_OF_SERVICE: 'TERMS_OF_SERVICE',
};

export const AttestationValues = {
  [Attestations.PRIVACY_POLICY]: {
    label: 'Privacy Policy',
    textValue: 'PRIVACY_POLICY',
    markDownValue: PrivacyPolicyMarkdown,
  },
  [Attestations.TERMS_OF_SERVICE]: {
    label: 'Terms of Service',
    textValue: TermsOfServiceMarkdown,
    markDownValue: TermsOfServiceMarkdown,
  },
};
