import { useCallback, useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Card, Stack, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// import { fData } from 'utils/formatNumber';
import { countries, ResponseType } from 'constants';
import { FormProvider, RHFSelect, RHFTextField } from 'components/hook-form';
import Iconify from 'components/Iconify';
import { regions, provinces as states, barangays, cities } from 'select-philippines-address';

export default function AddressForm({
  address,
  additionalActionOnSave = () => {},
  handleOnSubmit,
  disabledReset = false,
  columnView = false,
}) {
  // const { user, isEmailVerified } = useAuth();

  const [forFirstLoadOnly, setForFirstLoadOnly] = useState(true);
  const [countryIsPhil, setCountryIsPhil] = useState(true);
  const [countryCodePhoneCode, setCountryCodePhoneCode] = useState('');

  const regList = useRef([]);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [barangayList, setBarangayList] = useState([]);

  const UpdateUserSchema = Yup.object().shape({
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    barangay: Yup.string().required('Barangay is required'),
    addressLine1: Yup.string().required('Address Line is required'),
    city: Yup.string().required('City is required'),
    region: Yup.string().required('Region is required'),
  });

  const defaultValues = {
    country: address?.country || 'Philippines',
    region: address?.region || '',
    regionCode: address?.regionCode || '',
    addressLine1: address?.addressLine1 || '',
    state: address?.state || '',
    stateCode: address?.stateCode || '',
    barangay: address?.barangay || '',
    barangayCode: address?.barangayCode || '',
    city: address?.city || '',
    cityCode: address?.cityCode || '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset,
  } = methods;

  const watchCountry = watch('country');

  // initialize country code
  useEffect(() => {
    setCountryCodePhoneCode(countries.find((val) => val.label === defaultValues.country)?.phone || '');
  }, []);

  // Initialize Arrays of address options
  useEffect(() => {
    if (forFirstLoadOnly) {
      // let listOfStates = [];
      // let listOfCities = [];

      // let listOfBarangays = [];

      if (countryIsPhil) {
        regions().then((regs) => {
          regList.current = regs;
        });

        states(address?.regionCode)
          .then((list) => setStateList(list))
          .catch(() => {
            setStateList([]);
          });
        cities(address?.stateCode)
          .then((list) => setCityList(list))
          .catch(() => {
            setCityList([]);
          });
        barangays(address?.cityCode)
          .then((list) => setBarangayList(list))
          .catch(() => {
            setBarangayList([]);
          });
      }

      setForFirstLoadOnly(false);
    }

    return () => {
      setForFirstLoadOnly(false);
    };
  }, [countryIsPhil, address, setForFirstLoadOnly, setCityList, setStateList, setBarangayList, regList]);

  // Country Change
  useEffect(() => {
    setCountryCodePhoneCode(countries.find((val) => val.label === watchCountry)?.phone || '');
    setCountryIsPhil(Boolean(watchCountry === 'Philippines'));
  }, [watchCountry]);

  const watchRegionChange = watch('region');

  // Change Watch
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const isTypeChange = type === 'change';
      const isRegionChange = isTypeChange && name === 'region';
      const isStateChanges = isTypeChange && name === 'state';
      const isCityChanges = isTypeChange && name === 'city';
      const isBarangayChanges = isTypeChange && name === 'barangay';

      const code = regList.current.find((item) => item.region_name === value?.region)?.region_code;
      if (code && isRegionChange) {
        setValue('regionCode', code);
        setValue('stateCode', '');
        setValue('state', '');
        setValue('cityCode', '');
        setValue('city', '');
        setValue('barangayCode', '');
        setValue('barangay', '');
        setValue('addressLine1', '');
        states(code).then((stateList) => {
          setStateList(stateList);
        });
        setCityList([]);
        setBarangayList([]);
      } else if (isStateChanges) {
        const code = stateList.find((item) => item.province_name === value?.state)?.province_code;
        if (code) {
          setValue('stateCode', code);
          setValue('cityCode', '');
          setValue('city', '');
          setValue('barangayCode', '');
          setValue('barangay', '');
          setValue('addressLine1', '');
          cities(code).then((cityList) => {
            setCityList(cityList);
          });
          setBarangayList([]);
        }
      } else if (isCityChanges) {
        const code = cityList.find((item) => item.city_name === value?.city)?.city_code;
        if (code) {
          setValue('cityCode', code);
          setValue('barangayCode', '');
          setValue('barangay', '');
          setValue('addressLine1', '');
          barangays(code).then((barangayList) => {
            setBarangayList(barangayList);
          });
        }
      } else if (isBarangayChanges) {
        const code = barangayList.find((item) => item.brgy_name === value?.barangay)?.brgy_code;
        if (code) {
          setValue('barangayCode', code);
          setValue('addressLine1', '');
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [
    setBarangayList,
    setValue,
    setCityList,
    regList,
    barangayList,
    cityList,
    stateList,
    setStateList,
    watchRegionChange,
  ]);

  const onSubmit = useCallback(
    async (values) => {
      try {
        const response = await handleOnSubmit(values);
        if (response.payload === ResponseType.success) {
          additionalActionOnSave();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [countryCodePhoneCode]
  );

  const handleResetClick = useCallback(() => {
    reset();
  }, []);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'photoURL',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: columnView ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)' },
              }}
            >
              <RHFSelect name="country" label="Country" placeholder="Country">
                <option value="" />
                {countries.map((option) => (
                  <option key={option.code} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </RHFSelect>
              {countryIsPhil ? (
                <RHFSelect name="region" label="Region" placeholder="region">
                  <option value="" />
                  {(regList?.current ?? []).map((option) => (
                    <option key={option.id} value={option.region_name}>
                      {option.region_name}
                    </option>
                  ))}
                </RHFSelect>
              ) : (
                <RHFTextField name="region" label="Region" />
              )}

              {countryIsPhil ? (
                <RHFSelect name="state" label="State" placeholder="state">
                  <option value="" />
                  {stateList.map((option) => (
                    <option key={option.province_code} value={option.province_name}>
                      {option.province_name}
                    </option>
                  ))}
                </RHFSelect>
              ) : (
                <RHFTextField name="state" label="State" />
              )}

              {countryIsPhil ? (
                <RHFSelect name="city" label="City" placeholder="city">
                  <option value="" />
                  {cityList.map((option) => (
                    <option key={option.city_code} value={option.city_name}>
                      {option.city_name}
                    </option>
                  ))}
                </RHFSelect>
              ) : (
                <RHFTextField name="city" label="City" />
              )}

              {countryIsPhil ? (
                <RHFSelect name="barangay" label="Barangay" placeholder="barangay">
                  <option value="" />
                  {barangayList.map((option) => (
                    <option key={option.brgy_code} value={option.brgy_name}>
                      {option.brgy_name}
                    </option>
                  ))}
                </RHFSelect>
              ) : (
                <RHFTextField name="barangay" label="barangay" />
              )}
              <RHFTextField name="addressLine1" label="Street Address" />
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                {!disabledReset && (
                  <Button
                    size="small"
                    startIcon={<Iconify icon={'bx:reset'} />}
                    onClick={handleResetClick}
                    sx={{ mr: 3 }}
                  >
                    Reset
                  </Button>
                )}
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Save Changes
                </LoadingButton>
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
