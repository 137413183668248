export const createRacebibPreFixCreator = (distance) => {
  const stringDistance = distance.toString();
  let prefix = stringDistance.substring(0, 1);

  // Distance is all numbers
  const isNumeric = /^\d+$/.test(stringDistance);
  if (isNumeric) {
    prefix =
      stringDistance?.length >= 3
        ? stringDistance.substring(0, 3)
        : stringDistance?.length === 2
        ? stringDistance.substring(0, 2)
        : stringDistance.substring(0, 1);
  } else if (distance?.length) {
    const abbreviation = stringDistance
      .match(/\b(\w)/g)
      .join('')
      .toUpperCase();
    prefix = abbreviation;
  }

  return prefix;
};

export const doesArrayContainObject = (array, obj) => {
  // eslint-disable-next-line
  for (let i = 0; i < array.length; i++) {
    if (areObjectsEqual(array[i], obj)) {
      return true;
    }
  }
  return false;
};

export const areObjectsEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }
  // eslint-disable-next-line
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};
