import { useCallback, useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Storage as S3Storage } from 'aws-amplify';
import { S3FileType, S3ImageSizes } from 'constants/index';
import moment from 'moment-timezone';
import { upsertDeleteImageLink } from 'modules/organization/actions';
import {
  formatKeyImageUrl,
  keyS3Construction,
  fileNameFormatter,
  getFileNameFromKeyValue,
  getLocalStorageItem,
  setLocalStorageItem,
  LOCAL_STORAGE,
  isExpiredImageUrl,
  imageSizesKeys,
  removeLocalStorageItem,
  getFileNameFromKeyValueWithS3Access,
} from 'utils/index';

export const useS3Hook = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const [progress, setProgress] = useState(null);
  const [totalSize, setTotalSize] = useState(0);
  const [isComplete, setIsComplete] = useState(false);

  const imageUrlRef = useRef('');

  const getImage = useCallback(
    async (key, type = '', options, size = '') => {
      try {
        setIsComplete(false);

        const fileName = formatKeyImageUrl(key, { size, ...options, type });

        const formateFileNameFromKey = getFileNameFromKeyValue(key);

        let imageUrl = getLocalStorageItem(formateFileNameFromKey, LOCAL_STORAGE.PICK_PACER_IMAGES) || '';

        if (imageUrl && !isExpiredImageUrl(imageUrl, moment)) {
          return imageUrl;
        }

        // check item if it exists
        const testKey = await S3Storage.list(fileName);

        if (testKey?.length) {
          imageUrl = await S3Storage.get(fileName, {
            level: 'public',
            expires: 60, // 1 minute
          });
        }
        // if nothing is pulled get the original image
        else {
          // Setting the size to null will get the original file
          imageUrl = await S3Storage.get(formatKeyImageUrl(key, { ...options, type, size: null }), {
            level: 'public',
            expires: 60, // 1 minute
          });
        }

        setLocalStorageItem({ [formateFileNameFromKey]: imageUrl }, LOCAL_STORAGE.PICK_PACER_IMAGES);

        imageUrlRef.current = imageUrl;
        setIsComplete(true);
        return imageUrl;
      } catch (error) {
        console.log('Error getting file: ', error);
        setIsComplete(true);
        return '';
      }
    },
    [setIsComplete, imageUrlRef, params]
  );

  const handleDeleteImage = useCallback(
    async ({ preview, key, id: belongsToId = '', options = { level: 'public' } }) => {
      const { level = 'public' } = options;
      try {
        setIsComplete(false);
        await S3Storage.remove(key, { level });

        const formateFileNameFromKeyArr = imageSizesKeys?.reduce((acc, size) => {
          const keyObj = getFileNameFromKeyValue(key, size, true);
          return [...acc, keyObj];
        }, []);

        formateFileNameFromKeyArr?.forEach((itemKey) =>
          removeLocalStorageItem(itemKey, LOCAL_STORAGE.PICK_PACER_IMAGES)
        );

        if (belongsToId) {
          dispatch(upsertDeleteImageLink({ id: belongsToId }, true));
        }

        setIsComplete(true);
      } catch (error) {
        console.log('Error ', error);
        setIsComplete(true);
      }
    },
    []
  );

  const handleDrop = useCallback(
    async (acceptedFiles, type, options) => {
      const file = acceptedFiles[0];
      setIsComplete(false);

      const belongsTo = options?.belongsTo || options?.orgId || options?.userId;

      if (file) {
        try {
          const fileName = `${type || ''}_${belongsTo}${options?.index !== undefined ? `_${options?.index}` : ''}.${
            file.type.split('/')[1]
          }`; // profile_172376172367123_original.jpg

          const constructedKey = keyS3Construction({ type, ...params }, fileName);
          const s3Response = await S3Storage.put(constructedKey, file, {
            contentType: file.type, // contentType is optional
            progressCallback(progress) {
              setTotalSize(progress.total);
              setProgress(() => Math.round((100 * progress.loaded) / progress.total));
              if (progress.loaded === progress.total) {
                setIsComplete(true);
                setProgress(null);
              }
            },
          });

          // Remove Item from the Local Storage
          const formateFileNameFromKeyArr = imageSizesKeys?.reduce((acc, size) => {
            const keyObj = getFileNameFromKeyValue(constructedKey, size, true);
            return [...acc, keyObj];
          }, []);

          formateFileNameFromKeyArr?.forEach((itemKey) =>
            removeLocalStorageItem(itemKey, LOCAL_STORAGE.PICK_PACER_IMAGES)
          );

          if (options?.updateDynamoImageLink) {
            dispatch(upsertDeleteImageLink({ ...options, belongsTo, urlImage: s3Response?.key || '' }));
          }

          if (s3Response?.key) {
            const imageUrl = await getImage(s3Response?.key);
            return imageUrl;
          }

          return '';
        } catch (error) {
          console.log('Error uploading file: ', error);
          setIsComplete(true);
          return '';
        }
      }
    },
    [setTotalSize, setProgress, getImage, params]
  );

  return {
    handleDrop,
    handleDeleteImage,
    getImage,
    imageUrlRef: imageUrlRef.current,
    progress,
    totalSize,
    isComplete,
  };
};

export const useRenderImageHook = ({ imageUrl, type = S3FileType.profile, size = S3ImageSizes.original }) => {
  const { getImage, imageUrlRef } = useS3Hook();
  const [value, setValue] = useState('');

  useEffect(() => {
    if (imageUrl) {
      (async () => {
        const item = await getImage(imageUrl, type, { S3FileName: imageUrl }, size);
        setValue(item);
      })();
    }
  }, [imageUrlRef, imageUrl]);

  return value;
};
