import PropTypes from 'prop-types';
import { useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer } from '@mui/material';
// hooks
import useResponsive from 'hooks/useResponsive';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
// utils
import cssStyles from 'utils/cssStyles';
// config
import { NAVBAR } from 'config';
// components
import Logo from 'components/Logo';
import Scrollbar from 'components/Scrollbar';
import { NavSectionVertical } from 'components/nav-section';
import {
  isAuthSelector,
  userFullNameSelector,
  userEmailSelector,
  isEmailConfirmedSelector,
  isAuthAndConfirmedSelector,
  userOrganizedEventsSelector,
  userProfileImageObjSelector,
} from 'modules/user/selector';
import { profileSelector } from 'modules/app/selector';
import {
  selectedOrganizationIdSelector,
  selectedEventIdSelector,
  getUserOrganizationByIdSelector,
  isUserOrgAdminSelector,
  organizationDataSelector,
} from 'modules/organization/selector';
import {
  categoriesSelector,
  eventNameSelector,
  isMetricUsedIsKmSelector,
  isUserAdminSelector,
} from 'modules/event/selector';
import { Routes, ProfileView, OrganizationRole, Roles } from 'constants/index';
import { getPath } from 'utils/routes';
import {
  userAuthNavigation,
  useNonAuthNavigation,
  organizationsNavigation,
  eventPortalNavigation,
  organizationPortalNavigation,
} from './NavConfig';
import NavbarDocs from './NavbarDocs';

import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';

export const NavbarVertical = ({ isOpenSidebar, onCloseSidebar }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isAuth = useSelector(isAuthSelector);
  const isAuthAndConfirmed = useSelector(isAuthAndConfirmedSelector);
  const isEmailConfirmed = useSelector(isEmailConfirmedSelector);
  const email = useSelector(userEmailSelector);
  const fullName = useSelector(userFullNameSelector);
  const userProfileImageObj = useSelector(userProfileImageObjSelector);
  const selectedEventId = useSelector(selectedEventIdSelector);
  const selectedOrganizationId = useSelector(selectedOrganizationIdSelector) ?? '';
  const userOrganizedEvents = useSelector(userOrganizedEventsSelector);
  const isUserOrgAdmin = useSelector(isUserOrgAdminSelector);
  const eventCategories = useSelector(categoriesSelector);
  const metricUsedIsKm = useSelector(isMetricUsedIsKmSelector);
  const organizationData = useSelector(organizationDataSelector);
  const eventName = useSelector(eventNameSelector);
  const isUserEventAdmin = useSelector(isUserAdminSelector);

  const userOrganization = useSelector(getUserOrganizationByIdSelector(selectedOrganizationId)) || null;
  const isOneOfUserOrganization = Boolean(userOrganization);

  const profile = useSelector(profileSelector);
  const isInOrganizationProfile = profile === ProfileView.organization;

  const { pathname } = useLocation();

  const navigation = useMemo(() => {
    if (isAuth && isEmailConfirmed) {
      if (isInOrganizationProfile) {
        if (!selectedOrganizationId) {
          return organizationsNavigation;
        }

        if (selectedOrganizationId && selectedEventId) {
          return eventPortalNavigation({
            categories: eventCategories || [],
            metricUsedIsKm,
            organizationId: selectedOrganizationId,
          });
        }

        if (selectedOrganizationId && isOneOfUserOrganization) {
          if (!isUserOrgAdmin) {
            return organizationPortalNavigation?.map((nav) => ({
              ...nav,
              items: nav.items.filter(
                (item) =>
                  !(
                    item?.roles &&
                    (item?.roles?.includes(OrganizationRole.ADMIN) || item?.roles?.includes(OrganizationRole.CREATOR))
                  )
              ),
            }));
          }
          return organizationPortalNavigation;
        }

        if (!userOrganizedEvents?.length) {
          return organizationsNavigation.map((val) => ({
            ...val,
            items: val.items.filter((navItem) => navItem.path !== getPath(Routes.ORGANIZATION_EVENTS)),
          }));
        }

        return organizationsNavigation;
      }
      return userAuthNavigation.map((val) => ({
        ...val,
        items: val.items.filter((navItem) => navItem.path !== getPath(Routes.VERIFICATION)),
      }));
    }

    if (isAuth) {
      return userAuthNavigation;
    }

    return useNonAuthNavigation;
  }, [
    isInOrganizationProfile,
    isAuth,
    isEmailConfirmed,
    selectedEventId,
    selectedOrganizationId,
    isOneOfUserOrganization,
    isUserOrgAdmin,
    eventCategories,
    metricUsedIsKm,
  ]);

  const isDesktop = useResponsive('up', 'lg');

  const handleProfileClick = useCallback(() => {
    dispatch(push(Routes.PROFILE));
  }, [dispatch]);

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  let dataToRender = null;

  if (isInOrganizationProfile) {
    if (selectedEventId) {
      dataToRender = { name: eventName, subText: isUserEventAdmin ? Roles.ADMIN : Roles.MEMBER };
    } else {
      dataToRender = { name: organizationData?.organizationName, subText: isUserOrgAdmin ? Roles.ADMIN : Roles.MEMBER };
    }
  } else if (isAuth) {
    dataToRender = { name: fullName, subText: email, profilePhoto: userProfileImageObj };
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Logo to={isInOrganizationProfile ? getPath(Routes.ORGANIZATION_DASHBOARD) : '/'} />

          {isDesktop && !isCollapse && (
            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          )}
        </Stack>

        {dataToRender?.name && (
          <NavbarAccount isCollapse={isCollapse} onClick={handleProfileClick} data={dataToRender} />
        )}
      </Stack>

      <NavSectionVertical navConfig={navigation} isCollapse={isCollapse} />

      <Box sx={{ flexGrow: 1 }} />

      {profile === ProfileView.user && isAuthAndConfirmed && <NavbarDocs />}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
};

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));
