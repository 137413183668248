import moment from 'moment-timezone';
import { PriceType, ReturnType } from '../../../constants/common/index'; // NOTE: don't configure into common importing this process is also used in the backend and formatting is needed as this is in common
import { fCurrency, fromCentsFormat } from '../formatNumber'; // NOTE: don't configure into common importing this process is also used in the backend and formatting is needed as this is in common

export const getActiveLatestPrice = (prices, returnType, shouldBeFormatedInToCents = true) => {
  let returnValue = null;
  const filteredPrices = prices?.filter((price) => price?.enabled);

  const ascendingPriceByValidFrom = filteredPrices?.sort((a, b) =>
    moment(a.priceValidFrom).diff(moment(b.priceValidFrom))
  );

  const ascendingPriceByValidTo = ascendingPriceByValidFrom?.filter((price) =>
    moment().isBefore(moment(price.priceValidTo))
  );

  returnValue = ascendingPriceByValidTo;

  const regularPrice = prices?.find((price) => price?.type === PriceType.REGULAR_PRICE);

  if (regularPrice && !ascendingPriceByValidTo?.length) {
    returnValue = [regularPrice];
  }

  if (ReturnType.SortedNameValue === returnType) {
    returnValue = returnValue?.reduce(
      (acc, item) => [
        ...acc,
        {
          name: item?.type ?? 'Regular Price',
          value: `${fCurrency(fromCentsFormat(item?.amountInCent), item?.currency)}
                   ${
                     returnValue.length > 1
                       ? `(Valid Until ${moment(item.priceValidTo).format('MM/DD/YYYY - hh:MM A')})`
                       : ''
                   }`,
        },
      ],
      []
    );
  } else if (ReturnType.ValueOnlyWithCurrencyFormat === returnType) {
    returnValue = returnValue?.reduce(
      (acc, item) => [
        ...acc,
        {
          type: `${fCurrency(fromCentsFormat(item?.amountInCent), item?.currency)}`,
          amountInCent: shouldBeFormatedInToCents ? fromCentsFormat(item?.amountInCent) : item?.amountInCent || null,
          currency: item?.currency || 'PHP',
        },
      ],
      []
    );
  }

  return returnValue;
};

export const calculatedTotalCharges = (charges) => {
  const total = charges?.reduce((acc, item) => Number(acc) + Number(item?.amountInCent || 0), 0);

  if (!total) {
    return null;
  }

  return {
    type: '',
    amountInCent: total,
    currency: charges?.[0]?.currency || 'PHP',
    name: `${fCurrency(fromCentsFormat(total), charges?.[0]?.currency)}`,
  };
};
