import { format, getTime, formatDistanceToNow } from 'date-fns';
import moment from 'moment-timezone';
import { TimeReturnType } from 'constants';

// ----------------------------------------------------------------------

export function fDate(date = '') {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date = '') {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date = '') {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date = '') {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date = '') {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fTimeOnly(date = '') {
  return format(new Date(date), 'HH:mm');
}

export function getTimeDifference(timeA, timeB, TimeReturnTypeVal) {
  const diffTime = moment(timeA).diff(moment(timeB), 'seconds');

  const diffInSec = moment.utc(moment.duration(diffTime, 'seconds').asMilliseconds());

  if (TimeReturnTypeVal === TimeReturnType.MinuteSecAndInWord) {
    return diffInSec.format('HH [hrs] mm [mins]');
  }

  return diffInSec.format('hh:mm:ss');
}
