import { createAction } from 'redux-actions';
import { Logger } from 'aws-amplify';
import fetch from 'utils/fetch';
import { generatePath } from 'react-router-dom';
import { push } from 'connected-react-router';
import { createAlert } from 'modules/alerts/actions';
import { getPath, titleUrlFormatter, getActiveLatestPrice } from 'utils';
import {
  Routes,
  ProgressPage,
  ReturnType,
  CheckoutErrorType,
  CheckoutState,
  PaymentStatus,
  RegistrationState,
} from 'constants/index';
import {
  createDraftRegistration as createDraftRegistrationFunction,
  resetDraftRegistration as resetDraftRegistrationFunction,
  createRegistrantData as createRegistrantDataMutation,
  updateEventRegistrationOrder as updateEventRegistrationOrderMutation,
  updateRegistrantData as updateRegistrantDataMutation,
  getCalculatedRegistrationCart as getCalculatedRegistrationCartMutation,
  preCheckoutChecker as preCheckoutCheckerFunctionCall,
} from 'graphql/mutations';
import { registrationByUserId as registrationByUserIdQuery } from 'graphql/customQueries';
import {
  userEmailSelector,
  userDataSelector,
  userIdSelector,
  billingShippingAddressSelector,
} from 'modules/user/selector';
import { eventDataSelector, getEventCategorySelector } from 'modules/events/selector';
import { getRegistrationOrderDetails, fetchEventRegistrants } from 'modules/event/actions';
import {
  registrationDetailsSelector,
  userChoiceCategoriesSelector,
  userChoiceCategoryByIdSelector,
  registrationCartSummarySelector,
} from './selector';
import {
  ADD_TO_USERCHOICE_CATEGORIES,
  QUANTITY_CHANGE_TO_USERCHOICE_CATEGORY,
  CREATE_DRAFT_REGISTRATION,
  REGISTRATION_ORDER_DATA,
  FETCH_USER_REGISTRATION_DETAILS,
  CHANGE_REGISTRATION_PROGRESS,
  DELETE_REGISTRATION_ORDER,
  UPSERT_REGISTRANT_DATA,
  CALCULATE_REGISTRATION_CART_SUMMARY,
  UPDATE_REGISTRATION_FETCHED_STATUS,
  GET_CALCULATED_REGISTRATION_CART,
  FETCHING_REGISTRATION_CART_SUMMARY,
  PRE_CHECKOUT_CHECKER,
  RESET_CART_ORDER,
} from './types';

const logger = new Logger('events/actions');

export const addUserChoiceCategory = createAction(ADD_TO_USERCHOICE_CATEGORIES, () => (dispatch, getState) => {
  return [];
});

export const decreaseUserChoiceCategoryById = createAction(
  QUANTITY_CHANGE_TO_USERCHOICE_CATEGORY,
  (id) => (dispatch, getState) => {
    const state = getState();
    const category = userChoiceCategoryByIdSelector(id)(state);

    const quantity = category?.quantity && category?.quantity > 0 ? category?.quantity - 1 : 0;

    if (id) {
      dispatch(calculateRegistrationCartSummary(id, quantity));
    }

    return { id, quantity };
  }
);

export const increaseUserChoiceCategoryById = createAction(
  QUANTITY_CHANGE_TO_USERCHOICE_CATEGORY,
  (id) => (dispatch, getState) => {
    const state = getState();
    const userChoiceCategory = userChoiceCategoryByIdSelector(id)(state);

    const quantity =
      userChoiceCategory?.quantity && userChoiceCategory?.quantity > 0 ? userChoiceCategory?.quantity + 1 : 1;

    if (id) {
      dispatch(calculateRegistrationCartSummary(id, quantity));
    }
    return { id, quantity };
  }
);

export const calculateRegistrationCartSummary = createAction(
  CALCULATE_REGISTRATION_CART_SUMMARY,
  (catId, quantity) => (dispatch, getState) => {
    const state = getState();
    const eventCategory = getEventCategorySelector(catId)(state);
    const registrationCart = registrationCartSummarySelector(state);

    const prices = eventCategory?.regPrice?.prices;
    const distance = eventCategory?.distance;

    const currentPrice = getActiveLatestPrice(prices || [], ReturnType.ValueOnlyWithCurrencyFormat, false)?.[0] || null;
    const newLineItems = registrationCart?.lineItems?.filter((item) => item?.id !== catId);

    // if (!currentPrice || !prices?.[0]) {
    //   return registrationCart;
    // }

    newLineItems.push({
      id: catId,
      distance,
      quantity,
      price: currentPrice,
    });

    const calculatedSubTotal = newLineItems?.reduce(
      (acc, item) => ({
        currency: currentPrice?.currency || 'PHP',
        amountInCent: acc.amountInCent + (item?.price?.amountInCent || 0) * item.quantity,
        type: currentPrice?.type || '',
      }),
      {
        currency: 'PHP',
        amountInCent: 0,
        type: '',
      }
    );

    const calculatedTotal = calculatedSubTotal;
    const fees = [];
    const discounts = [];

    const value = {
      ...registrationCart,
      lineItems: newLineItems,
      discounts,
      fees,
      subTotal: calculatedSubTotal,
      total: calculatedTotal,
    };

    return value;
  }
);

export const createDraftRegistration = createAction(
  CREATE_DRAFT_REGISTRATION,
  async (registrationInfo, eventId, eventName) => async (dispatch, getState) => {
    const state = getState();
    const userChoiceCategories = userChoiceCategoriesSelector(state);
    const userEmail = userEmailSelector(state);
    const userId = userIdSelector(state);

    // 1. Filter quantity with value not
    const filteredByCategoriesWithQuantity = userChoiceCategories?.filter((cat) => cat?.quantity) || [];

    if (!filteredByCategoriesWithQuantity?.length) {
      return;
    }

    // 2. Call lambda to create draft registration
    try {
      const response = await fetch.graphql({
        query: createDraftRegistrationFunction,
        variables: {
          args: {
            categories: filteredByCategoriesWithQuantity,
            eventId,
            userEmail,
            userId,
            progress: ProgressPage.formFilling,
          },
        },
      });

      const createdDraftRegistration = JSON.parse(response?.data?.createDraftRegistration);

      if (!createdDraftRegistration?.id || createdDraftRegistration?.errors?.length) {
        dispatch(createAlert('CUSTOM_ERROR', 'Having Error Creating draft registration'));
        return;
      }
      const createDraftOrderDispatch = {
        ...createdDraftRegistration,
        lineItems: {
          items: createdDraftRegistration.lineItems,
        },
      };

      dispatch(registrationOrderData(createDraftOrderDispatch));

      // 3. redirect user to the next page
      dispatch(changeRegistrationProgress(ProgressPage.formFilling));
    } catch (error) {
      dispatch(createAlert('CUSTOM_ERROR', 'Having Error Creating draft registration'));
      console.log(error);
    }
  }
);

export const registrationOrderData = createAction(
  REGISTRATION_ORDER_DATA,
  async (registrationDataUpdate = null, lineItemsUpdateOnly = null) =>
    async (dispatch, getState) => {
      const state = getState();
      const registrationDetails = registrationDetailsSelector(state);
      let returnValue = registrationDetails;

      if (registrationDataUpdate) {
        returnValue = registrationDataUpdate;
      }

      if (lineItemsUpdateOnly) {
        returnValue = {
          ...returnValue,
          lineItems: {
            ...returnValue.lineItems,
            items: lineItemsUpdateOnly || [],
          },
        };
      }

      return returnValue;
    }
);

export const fetchUserEventOrder = createAction(
  FETCH_USER_REGISTRATION_DETAILS,
  async (eventId, paymentStatus = PaymentStatus.UNPAID, registrationState = RegistrationState.PENDING) =>
    async (dispatch, getState) => {
      const state = getState();
      const userId = userIdSelector(state);

      const filter = {
        and: [
          { eventId: { eq: `${eventId}` } },
          { paymentStatus: { eq: `${paymentStatus}` } },
          { registrationState: { eq: `${registrationState}` } },
        ],
      };

      try {
        const response = await fetch.graphql({
          query: registrationByUserIdQuery,
          variables: { userId, filter },
        });

        const registrationByUserIdResponse = response?.data?.registrationByUserId?.items?.[0] || {};

        const orderId = registrationByUserIdResponse?.id || null;
        if (orderId) {
          await dispatch(getCalculatedRegistrationCart(orderId));
        }

        return registrationByUserIdResponse;
      } catch (error) {
        console.log(error);
      }
    }
);

export const resetCart = createAction(RESET_CART_ORDER, (value) => () => value);

export const resetRegistration = createAction(
  DELETE_REGISTRATION_ORDER,
  async (id = '') =>
    async (dispatch, getState) => {
      const state = getState();
      const registrationDetails = registrationDetailsSelector(state);

      if (!registrationDetails?.id && !id) {
        return;
      }

      // 1. Call lambda to Remove user draft registration
      try {
        dispatch(fetchingRegistrationCartSummary(true, false));

        const response = await fetch.graphql({
          query: resetDraftRegistrationFunction,
          variables: {
            args: {
              orderId: id || registrationDetails?.id,
            },
          },
        });

        const resetDraftRegistrationRes = JSON.parse(response?.data?.resetDraftRegistration);

        if (resetDraftRegistrationRes?.success && id) {
          dispatch(createAlert('CUSTOM_SUCCESS', 'Successfully deleted registration'));
        }
        return resetDraftRegistrationRes?.success;
      } catch (error) {
        console.log(error);
      }
    }
);

export const preCheckoutChecker = createAction(PRE_CHECKOUT_CHECKER, async (payload) => async (dispatch, getState) => {
  const state = getState();
  const registrationDetails = registrationDetailsSelector(state);
  const registrationCartSummary = registrationCartSummarySelector(state);
  const event = eventDataSelector(state);
  const totalCartAmount = registrationCartSummary?.total;
  const userBillingAndShipping = billingShippingAddressSelector(state);
  const user = userDataSelector(state);

  if (!registrationDetails?.id) {
    return;
  }
  const userData = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phoneNumber: user?.phoneNumber || '',
  };

  const host = `${window.location.protocol}//${window.location.host}`;

  const orderLink = `${host}${generatePath(getPath(Routes.MY_RACES), {
    orderId: registrationDetails?.id,
  })}`;

  const redirects = {
    success: `${host}${generatePath(getPath(Routes.REGISTRATION_CONFIRMATION), {
      orderId: registrationDetails?.id,
      eventId: event?.id,
      eventName: titleUrlFormatter(event.eventName),
      checkoutState: CheckoutState.success,
    })}`,
    failure: `${host}${generatePath(getPath(Routes.REGISTRATION_CONFIRMATION), {
      orderId: registrationDetails?.id,
      eventId: event?.id,
      eventName: titleUrlFormatter(event.eventName),
      checkoutState: CheckoutState.failed,
    })}`,
  };

  // 1. Call lambda For Pre Checkout Function
  try {
    const response = await fetch.graphql({
      query: preCheckoutCheckerFunctionCall,
      variables: {
        args: {
          orderId: registrationDetails?.id,
          totalCartAmount,
          eventId: event?.id,
          billingShippingAddress: userBillingAndShipping,
          checkoutOptions: payload,
          userData,
          orderLink,
          redirects,
        },
      },
    });

    const preCheckoutCheckerRes = JSON.parse(response?.data?.preCheckoutChecker);

    if (!preCheckoutCheckerRes?.success || preCheckoutCheckerRes?.checkoutError?.type) {
      const checkoutError = preCheckoutCheckerRes?.checkoutError;

      if (Object.keys(CheckoutErrorType).includes(checkoutError?.type)) {
        return { message: checkoutError?.message || '', type: checkoutError?.type };
      }

      return dispatch(
        createAlert(
          'CUSTOM_ERROR',
          checkoutError?.message || 'We are having issues processing your order. Please try again'
        )
      );
    }

    return {
      type: preCheckoutCheckerRes?.responseData?.data?.attributes?.type,
      redirect: preCheckoutCheckerRes?.responseData?.data?.attributes?.redirect?.checkout_url || '',
    };
  } catch (error) {
    console.log(error);
  }
});

export const getCalculatedRegistrationCart = createAction(
  GET_CALCULATED_REGISTRATION_CART,
  async (orderId, paymentMethod) => async (dispatch, getState) => {
    const state = getState();
    const registrationDetails = registrationDetailsSelector(state);

    if (!registrationDetails?.id && !orderId) {
      return;
    }
    dispatch(fetchingRegistrationCartSummary(true, false));
    // 1. Call lambda to Remove user draft registration
    try {
      const response = await fetch.graphql({
        query: getCalculatedRegistrationCartMutation,
        variables: {
          args: {
            orderId: orderId ?? registrationDetails?.id,
            ...(paymentMethod && { paymentType: paymentMethod }),
          },
        },
      });

      const getCalculatedRegistrationCartRes = JSON.parse(response?.data?.getCalculatedRegistrationCart);
      dispatch(fetchingRegistrationCartSummary(false, getCalculatedRegistrationCartRes.success));
      if (!getCalculatedRegistrationCartRes.success) {
        dispatch(
          createAlert(
            'CUSTOM_ERROR',
            'We are having trouble getting your cart please reload the page or try again later.'
          )
        );
      }
      return getCalculatedRegistrationCartRes;
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchingRegistrationCartSummary = createAction(
  FETCHING_REGISTRATION_CART_SUMMARY,
  (state, stateSuccess = false) =>
    () => ({
      state,
      stateSuccess,
    })
);

export const changeRegistrationProgress = createAction(
  CHANGE_REGISTRATION_PROGRESS,
  (progress) => (dispatch, getState) => {
    const state = getState();
    const event = eventDataSelector(state);
    dispatch(
      push(
        generatePath(getPath(Routes.EVENTREGISTRATION), {
          title: titleUrlFormatter(event.eventName),
          id: event?.id,
          progressPage: progress,
        })
      )
    );
    return progress;
  }
);

export const upsertRegistrantData = createAction(
  UPSERT_REGISTRANT_DATA,
  async (
      values,
      // TODO have a functionality to detect if user has changed values except for titleNam
      valuesHasChanged,
      formNumber,
      lineItemId,
      categoryId,
      registrantDataId,
      teamTitleHasChanged,
      registrantsIds = [],
      comesFromAdmin = false
    ) =>
    async (dispatch, getState) => {
      const state = getState();
      const registrationDetails = registrationDetailsSelector(state);
      const event = eventDataSelector(state);
      const userEmail = userEmailSelector(state);

      if ((!registrationDetails?.id || !valuesHasChanged) && !comesFromAdmin) {
        return;
      }

      const upsertPayload = {
        ...(values?.teamName && { teamName: values?.teamName }),
        ...(values?.firstName && { firstName: values?.firstName || '' }),
        ...(values?.lastName && { lastName: values?.lastName || '' }),
        ...(values?.lastName &&
          values?.firstName && { fullName: `${values?.firstName} ${values?.lastName}`.toLowerCase() }),
        ...(values?.gender && { gender: values?.gender || '' }),
        userData: JSON.stringify(values),
      };

      const payload = {
        eventId: event?.id,
        userEmail,
        formOrderNumber: formNumber,
        lineItemId,
        categoryId,
        orderId: registrationDetails?.id,
        teamName: values?.teamName,
        userData: JSON.stringify(values),
        firstName: values?.firstName || '',
        fullName: `${values?.firstName} ${values?.lastName}`.toLowerCase(),
        age: values?.age,
        lastName: values?.lastName || '',
      };

      try {
        // TODO check if there's already a team name with the inputed team name from the user and neglect yet the saving
        if (teamTitleHasChanged && registrantsIds.length) {
          Promise.all(
            registrantsIds.map(
              async (id) =>
                await fetch.graphql({
                  query: updateRegistrantDataMutation,
                  variables: {
                    input: { id, teamName: values?.teamName },
                  },
                })
            )
          );
        }

        if (registrantDataId) {
          // Update Registrant to order
          const responseUpdated = await fetch.graphql({
            query: updateRegistrantDataMutation,
            variables: {
              input: { id: registrantDataId, ...upsertPayload },
            },
          });

          const ordId = responseUpdated?.data?.updateRegistrantData?.id;
          if (comesFromAdmin && ordId) {
            dispatch(fetchEventRegistrants(undefined, {}, { ordId, updatedData: upsertPayload }));
            await dispatch(getRegistrationOrderDetails(ordId, true));
            return;
          }
        } else {
          // Add Registrant to order
          await fetch.graphql({
            query: createRegistrantDataMutation,
            variables: {
              input: payload,
            },
          });
        }

        await dispatch(fetchUserEventOrder(event?.id));
      } catch (error) {
        dispatch(createAlert('CUSTOM_ERROR', 'We are having trouble saving your data. Please try again later'));
      }
    }
);

export const updateEventRegistrationOrder = createAction(
  UPDATE_REGISTRATION_FETCHED_STATUS,
  async (params = {}) =>
    async (dispatch, getState) => {
      const state = getState();
      const registrationDetails = registrationDetailsSelector(state);

      const payload = {
        id: registrationDetails?.id,
        ...params,
      };

      try {
        await fetch.graphql({
          query: updateEventRegistrationOrderMutation,
          variables: {
            input: payload,
          },
        });

        if (params?.progress) {
          dispatch(changeRegistrationProgress(params?.progress));
          return true;
        }

        return false;
      } catch (error) {
        dispatch(createAlert('CUSTOM_ERROR', 'We are having trouble saving your data. Please try again later'));
        return false;
      }
    }
);

export const updateRegistrationFetchedStatus = createAction(UPDATE_REGISTRATION_FETCHED_STATUS, (value) => () => value);
